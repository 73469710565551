import { element } from 'protractor';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataSettings } from './interfaces/settings';
import { environment } from 'src/environments/environment';
import { DataDepartment, Department } from './interfaces/departments';
import { DataDian, Dian } from './interfaces/dian';
import { nuevosempleos } from './interfaces/nuevosEmpleosClass';
import { ResponseNuevosEmpleos } from './interfaces/responseNuevosEmpleos';

const initDian:Dian[] =[ {
  idCatalogDane: 0,
  clase: 0,
  description: ''
}]

const initDepts:Department[]=[{
  idCatalogDept: 0,
  nombreDepto: '',
  codigoMunicipio:0,
  nombreMunicipio:''
}]


@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dian$ = new BehaviorSubject<Dian[]>(initDian)
  private depts$ = new BehaviorSubject<Department[]>(initDepts)
  private _API = environment.api

  formNuevosEmpleos = new nuevosempleos();
  private listPdf:Array<ArrayBuffer>;

  public nuevosEmpleosPost:string;

  constructor(private http:HttpClient) { 
  }

  get getformNuevosEmpleos ():nuevosempleos {
    return this.formNuevosEmpleos
  } 

  get dataDian$():Observable<Dian[]>{
    return this.dian$.asObservable();
  }

  get dataDept$():Observable<Department[]>{
    return this.depts$.asObservable();
  }

  setDataDepts(depts:Department[]):void{
    this.depts$.next(depts)
  }

  setDataDian(dian:Dian[]):void{
    this.dian$.next(dian);
  }

  setDocuments(documents:any){
    this.listPdf=documents;
  }

  getDataSettings():Observable<DataSettings>{
    return this.http.get<DataSettings>(`${this._API}/settings/`);
  }

  getDataDepartments():Observable<DataDepartment>{
    return this.http.get<DataDepartment>(`${this._API}/catalog_dept/`)
  }

  getDataDian():Observable<DataDian>{
    return this.http.get<DataDian>(`${this._API}/catalog_dane/`);
  }
  getDocuments():Array<ArrayBuffer>{
    return this.listPdf
  }

  setDataPost():Observable<ResponseNuevosEmpleos>{
    const formData = new FormData();
    this.listPdf.filter(Boolean).
    forEach((document) => formData.append("pdf",new Blob([document])));
    formData.append(
      "nuevosEmpleosRequest",
      new Blob([JSON.stringify(this.getformNuevosEmpleos)], {
        type: "application/json",
      })
    );
    formData.append("clientReCaptcha","03ANYolqsvpdlAVeIkU1FOr0qdK9uCIV17WonmTj3CP");
   
    return this.http.post<ResponseNuevosEmpleos>(`${this._API}/postulacion/`, formData);
  }
}
