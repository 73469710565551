import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicantJovenService } from '../services/applicant-joven.service';

export const PAGES = new Map([
  ['/aplicar-programa-nuevosempleos', ''],
  ['/informacion-nuevosempleos', '/aplicar-programa-nuevosempleos'],
  ['/requisitos-nuevosempleos', '/informacion-nuevosempleos'],
  ['/consentimiento-nuevosempleos', '/requisitos-nuevosempleos'],
  ['/confirmar-nuevosempleos', '/consentimiento-nuevosempleos'],
  ['/anexos-nuevosempleos', '/confirmar-nuevosempleos'],
  ['/finalizar-nuevosempleos', '/anexos-nuevosempleos'],
]);

@Injectable({
  providedIn: 'root'
})
export class RouteGuardJovenService implements CanActivate {
  constructor( private router: Router, private applicantService: ApplicantJovenService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {

    if (PAGES.get(state.url) !== this.applicantService.previosPage) {
      return true;
      
      window.location.href = 'https://www.grupobancolombia.com/personas/plan-apoyo-coronavirus/subsidio-pago-nomina';
    } else {
        return true;
    }

  }
}
