<div class="container" fxLayout="column">
    <span class="font-dark-nt-24">Responde las siguientes preguntas manifestando el cumplimiento de los
        requisitos</span>
    <p class="font-dark-os-16">
        De acuerdo con lo establecido en el Decreto 0533 de 2024 y la Resolución 2200 del 7 de junio de 2024, 
        sus modificaciones y sus normas reglamentarias, los requisitos manifestados a continuación son habilitantes 
        para continuar con la postulación al Incentivo a la Creación y Permanencia de Nuevos Empleos Formales,”. 
        Si no cumple con alguno de ellos, no podrá continuar con el proceso de solicitud.
        Su respuesta puede ser SI o NO dependiendo del caso
    </p>
    <form [formGroup]="form">
        <mat-card class="pd-2m">
            <div fxLayout="column" fxFlexAlign="stretch">
                <div fxLayout="row">
                    <div fxFlex="calc(70%)">
                        <span class="font-dark-os-sm-16">La entidad que represento:</span>
                    </div>
                    <div fxFlex="calc(30%)" fxLayoutAlign="space-around">
                        <span class="font-dark-os-sm-16">SI</span>
                        <span class="font-dark-os-sm-16">NO</span>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="calc(70%)">
                        <p class="font-dark-os-16">
                            Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito 
                            electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia 
                            de Economía Solidaria.
                        </p>
                    </div>
                    <div fxFlex="calc(30%)" fxFlexAlign="center">
                        <mat-radio-group (change)="choosen($event)" fxLayoutAlign="space-around" formControlName="cum1">
                            <mat-radio-button value="SI"></mat-radio-button>
                            <mat-radio-button value="NO"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="calc(70%)">
                        <p class="font-dark-os-16">
                            La participación de la nación y/o sus entidades descentralizadas es mayor 
                            al 50% de su capital.
                        </p>
                    </div>
                    <div fxFlex="calc(30%)" fxFlexAlign="center">
                        <mat-radio-group (change)="choosen($event)" fxLayoutAlign="space-around" formControlName="cum2">
                            <mat-radio-button value="SI"></mat-radio-button>
                            <mat-radio-button value="NO"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="calc(70%)">
                        <p class="font-dark-os-16">
                            Ha presentado esta misma solicitud ante otra entidad Financiera.
                        </p>
                    </div>
                    <div fxFlex="calc(30%)" fxFlexAlign="center">
                        <mat-radio-group (change)="choosen($event)" 
                        fxLayoutAlign="space-around" formControlName="cum3">
                            <mat-radio-button value="SI"></mat-radio-button>
                            <mat-radio-button value="NO"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="calc(70%)">
                        <p class="font-dark-os-16">
                            Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron 
                            el salario correspondiente a los 6 meses anteriores al mes de postulación
                        </p>
                    </div>
                    <div fxFlex="calc(30%)" fxFlexAlign="center">
                        <mat-radio-group (change)="choosen($event)" fxLayoutAlign="space-around" formControlName="cum4">
                            <mat-radio-button value="SI"></mat-radio-button>
                            <mat-radio-button value="NO"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="calc(70%)">
                        <p class="font-dark-os-16">
                            Los aportes correspondientes a todos los trabajadores que tuvo en el 
                            mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de 
                            Liquidación de Aportes (PILA).
                        </p>
                    </div>
                    <div fxFlex="calc(30%)" fxFlexAlign="center">
                        <mat-radio-group (change)="choosen($event)" fxLayoutAlign="space-around" formControlName="cum5">
                            <mat-radio-button value="SI"></mat-radio-button>
                            <mat-radio-button value="NO"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div *ngIf="isNatural" fxLayout="row">
                    <div fxFlex="calc(70%)">
                        <p class="font-dark-os-16">
                            Es una Persona Expuesta Políticamente (PEP), de acuerdo con lo establecido en el artículo
                            2.1.4.2.3 del decreto 1674 de 2016.
                        </p>
                    </div>
                    <div fxFlex="calc(30%)" fxFlexAlign="center">
                        <mat-radio-group (change)="choosen($event)" fxLayoutAlign="space-around" formControlName="nat1">
                            <mat-radio-button value="SI"></mat-radio-button>
                            <mat-radio-button value="NO"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div *ngIf="false" fxLayout="row">
                    <div fxFlex="calc(70%)">
                        <p class="font-dark-os-16">
                            Se encuentra debidamente registrada ante la Cámara de Comercio de su domicilio principal
                        </p>
                    </div>
                    <div fxFlex="calc(30%)" fxFlexAlign="center">
                        <mat-radio-group (change)="choosen($event)" fxLayoutAlign="space-around"
                            formControlName="coop1">
                            <mat-radio-button value="SI"></mat-radio-button>
                            <mat-radio-button value="NO"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </mat-card>
        <mat-card *ngIf="showPatronalCard" fxLayout="row wrap" fxLayoutAlign="start center" class="card-1">
            <span class="font-dark-os-16" fxFlex="100%">
                Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 
                67 y 68 del Código Sustantivo del Trabajo posterior al mes de mayo de 2023.
            </span>
            <mat-radio-group fxLayout fxLayoutAlign="space-between center" 
            (change)="changeState($event.value)" formControlName="cum6"
                fxFlex="20%" class="rd-1">
                <mat-radio-button value="SI">Si</mat-radio-button>
                <mat-radio-button value="NO">No</mat-radio-button>
            </mat-radio-group>
            <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="showPatronal" fxFlex="100%" class="mg-27">
                <span class="font-dark-os-16" fxFlex="100%">Ingresar el número de identificación del (los) empleador(es)
                    sustituido(s) persona natural o jurídica que hacen parte de la sustitución patronal o de
                    empleador.</span>
                <div fxLayout="row wrap" fxFlex="100%">
                    <div fxFlex="50%" fxFlex.lt-sm="50%" fxFlex.lt-md="100%" formArrayName="items"
                        *ngFor="let item of itemsArray.controls; let i = index;">
                        <mat-form-field fxFlex="100%" class="mf-1">
                            <input matInput appConsorcio type="text" minlength="3" maxlength="10" [formControlName]="i"
                                name="itemIdentificacion" id="itemIdentificacion"
                                placeholder="Número de identificación" />
                            <span matSuffix class="icon-pencil"></span>
                            <mat-error *ngIf="item.hasError('pattern')">
                                <span>Dato inválido</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div (click)="addItem()" [ngClass]="{'ds-field': countCS}" class="cs-1">
                    <i class="icon-plus-circle fa-2x"
                        style="--fa-secondary-color: black; --fa-primary-color: #ffd200; color:#ffd200"></i>
                    <span class="font-dark-nt-18" class="sp-1">
                        Agregar otro número de identificación
                    </span>
                </div>
            </div>
        </mat-card>
    </form>
    <div fxLayout fxLayoutAlign="center" fxFlexAlign="stretch" class="mgt-2">
        <button *ngIf="!showExit" mat-button class="btn-primary" [disabled]="form.invalid" (click)="next();">
            Continuar
        </button>
        <button *ngIf="showExit" mat-button class="btn-primary" (click)="exit();">
            Salir
        </button>
    </div>
</div>