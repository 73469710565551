import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from '../spinner-loading/spinner.service';
import { ApplicantService } from 'src/app/services/applicant.service';
import { ApplicantJovenService} from 'src/app/services/applicant-joven.service';
import { Router } from '@angular/router';

const docs = 'Regresar a los documentos';
const entendido = 'Entendido';

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements OnInit {
  public redirect =
    "https://www.bancolombia.com/personas/alternativas-financieras/subsidio-pago-nomina";
  showExit: boolean;
  showButton: boolean;
  showTitle: boolean;
  msgButton: string;

  constructor(
    private spinerService: SpinnerService,
    private router: Router,
    private applicantService: ApplicantService,
    private applicantJovenService: ApplicantJovenService,
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.validatePopUp();
  }

  validatePopUp() {
    if (this.data.code === "error") {
      this.showExit = false;
      this.showButton = false;
      this.showTitle = false;
    } else if (this.data.code === "confirmar") {
      this.msgButton = docs;
      this.showExit = true;
      this.showButton = true;
      this.showTitle = false;
    } else if (this.data.code === "infoMsg") {
      this.msgButton = entendido;
      this.showExit = true;
      this.showButton = true;
      this.showTitle = true;
    } else {
      this.showExit = true;
      this.showButton = false;
      this.showTitle = false;
    }
  }

  dismiss() {
    if (this.data.code === "infoMsg") {
      this.dialogRef.close(null);
      setTimeout(() => {
        this.applicantService.previosPage = `/${this.data.prevUrl}`;
        this.applicantJovenService.previosPage = `/${this.data.prevUrl}`;

        if (this.applicantService.previosPage === "/aplicar-programa") {
          this.router.navigate(["/informacion"]);
        } else if (
          this.applicantJovenService.previosPage ===
          "/aplicar-programa-nuevosempleos"
        ) {
          this.router.navigate(["/informacion-nuevosempleos"]);
        }
        // this.router.navigate([`/${this.data.nextUrl}`]);
      }, 200);
    } else {
      this.dialogRef.close(null);
    }
  }

  irComparadorError() {
    window.location.href = this.redirect;
  }

  irBancolombia() {
    window.location.href =
      "https://www.bancolombia.com/personas/alternativas-financieras/subsidio-pago-nomina";
  }

  irBancolombiaDocumentos() {
    window.open(
      "https://www.bancolombia.com/personas/alternativas-financieras/subsidio-pago-nomina"
    );
  }
  callSaveData() {
    if (this.data.code === "error") {
      window.location.href = this.redirect;
    } else {
      this.dialogRef.close(null);
    }
  }
}
