import { Injectable } from '@angular/core';
import { Applicant } from '../models/applicant.model';
import { Unavailability } from '../models/unavailability.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicantFront } from '../models/applicant-front.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicantService {

  public previosPage = '';
  private urlBase = window.location.origin;
  // private urlBase = 'https://qaabrircuenta.grupobancolombia.com';
  private url = '/com.bancolombia.subsidios.api/api/persistence/subCovid/save';
  // private urltest = this.urlBase+this.url;
  private urlContingencia = '/com.bancolombia.subsidios.api/api/persistence/subCovid/contingencia';
  public applicant: ApplicantFront = new ApplicantFront();
  public unavailability: Unavailability = new Unavailability();

  private pdfs: Array<ArrayBuffer>;

  constructor(private http: HttpClient) { }

  getApplicant(): ApplicantFront {
    return this.applicant;
  }

  setApplicant(applicant: ApplicantFront) {
    this.applicant = applicant;
  }

  setPayrollData(payrolls: string, conditionsAccepted: boolean): void {
    this.applicant.pil1_planillamesant = payrolls;
  }

  setPdfFiles(pdfs) {
    this.pdfs = pdfs;
  }

  saveData(): Observable<any> {
    const formData: FormData = new FormData()
    this.pdfs.filter(pdf => !!pdf)
      .forEach(pdfFile => formData.append('pdf', new Blob([pdfFile], {type: 'application/pdf'})) );

    formData.append('subCovidDto', new Blob([JSON.stringify(this.transformToBack(this.applicant))], {type: 'application/json'}));

    return this.http.post(this.url, formData);
    // return this.http.post(this.urltest, formData);
  }

  transformToBack(applicant: ApplicantFront): Applicant {
    return { ...applicant, numSolicitud: applicant.numSolicitud.numero };
  }

  saveDataContingencia(): Observable<any> {
    return this.http.post<any>(this.urlContingencia, this.transformToBack(this.applicant));
  }

  setDataUnavailability(title: string, description: string, activate: boolean) {
    this.unavailability.title = title;
    this.unavailability.description = description;
    this.unavailability.activate = activate;
  }
}
