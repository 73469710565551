<form [formGroup]="registerForm">
  <div fxLayout="column" fxLayoutGap="15px">
    <mat-card class="margin-xs" fxLayout="column">
      <div fxLayout="row" class="mt-10">
        <span class="font-dark-nt-24">Anexos</span>
      </div>
      <hr class="mat-divider-01" />

      <mat-card-content class="style-mat-1">
        <div class="mb-10">
          <span class="font-dark-os-16">
            Como soporte a tu solicitud y para verificar que puedes acceder al
            {{ tittle }}, debes anexar los siguientes documentos en formato PDF.
          </span>
        </div>
      </mat-card-content>
      <mat-card-content>
        

        <div class="text-info">
        <img class="img-icon" src="../../../assets/icons/fonts/info_FILL0_wght200_GRAD0_opsz48.svg" alt="info-img">

          <!-- <em class="icon-info-circle icon-alert"></em> -->
          <span class="font-dark-os-sm-16">
            Ten presente que debes utilizar solo los formularios oficiales y la
            última versión de los mismos descargada desde el sitio web de
            Bancolombia. Ninguno de los documentos que adjuntes debe ir
            protegido con claves y deben tener un peso máximo de 2MB cada uno.
            No olvides firmar el formulario de postulación y la certificación de
            cumplimiento de los requisitos
          </span>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between center"
      class="pd-card"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <em [class]="getIcon(state[0])" class="ic-file"></em>
        <p>
          Formulario de Postulación<br />
          <span class="font-light-gray-os-14"
            >Adjunta la versión del formulario actualizado, de lo contrario este
            documento no podrá ser procesado. Es importante que escanees y
            envíes las 3 páginas completas del formulario.</span
          >
        </p>
      </div>
      <app-upload-file
        (state)="showState($event, 0)"
        (getData)="getData($event, 'pdf1')"
      ></app-upload-file>
    </mat-card>
    <mat-card
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between center"
      class="pd-card"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <em [class]="getIcon(state[1])" class="ic-file"></em>
        <p>Certificación Cumplimiento de Requisitos.</p>
      </div>
      <app-upload-file
        (state)="showState($event, 1)"
        (getData)="getData($event, 'pdf2')"
      ></app-upload-file>
    </mat-card>
    <mat-card
      *ngIf="isJuridica || isCooperativa"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between center"
      class="pd-card"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <em [class]="getIcon(state[2])" class="ic-file"></em>
        <p>Certificado de Existencia y Representación Legal</p>
      </div>
      <app-upload-file
        (state)="showState($event, 2)"
        (getData)="getData($event, 'pdf3')"
      ></app-upload-file>
    </mat-card>
    <mat-card
      *ngIf="isConsorcio"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between center"
      class="pd-card"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <em [class]="getIcon(state[3])" class="ic-file"></em>
        <p>Poder otorgado al apoderado del consorcio o unión temporal.</p>
      </div>
      <app-upload-file
        (state)="showState($event, 3)"
        (getData)="getData($event, 'pdf4')"
      ></app-upload-file>
    </mat-card>
    <mat-card
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between center"
      class="pd-card"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <em [class]="getIcon(state[4])" class="ic-file"></em>
        <p>Registro Único Tributario (R.U.T.)</p>
      </div>
      <app-upload-file
        (state)="showState($event, 4)"
        (getData)="getData($event, 'pdf5')"
      ></app-upload-file>
    </mat-card>
    <mat-card class="margin-xs" fxLayout="column">
      <!-- <mat-card-header>
                <h3 class="font-dark-nt-24">
                    Presenta alguna de las siguientes Calidades Tributarias
                </h3>
            </mat-card-header> -->
      <div fxLayout="row" class="mt-10">
        <span class="font-dark-nt-24"
          >Presenta alguna de las siguientes Calidades Tributarias</span
        >
      </div>
      <hr class="mat-divider-01" />
      <div class="mt-20">
        <mat-checkbox
          formControlName="op1"
          value="1"
          class="example-margin mt-20"
          (change)="getCheckboxValues($event, 1); retenet()"
        >
          Impuesto renta y complementario régimen especial, Ingresos y
          patrimonio o Régimen Simple de Tributación – SIMPLE.
        </mat-checkbox>
      </div>
      <div class="mt-20">
        <mat-checkbox
          formControlName="op2"
          value="2"
          class="example-margin mt-20"
          (change)="getCheckboxValues($event, 2); retenet()"
        >
          Autorretenedor.
        </mat-checkbox>
      </div>

      <div class="mt-20">
        <mat-checkbox
          formControlName="op3"
          value="3"
          class="example-margin mt-20"
          (change)="getCheckboxValues($event, 3); retenet()"
        >
          Ninguna de las anteriores.
        </mat-checkbox>
      </div>
    </mat-card>
    <mat-card
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between center"
      class="pd-card"
      *ngIf="showRet"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <em [class]="getIcon(state[5])" class="ic-file"></em>
        <p>
          Resolución expedida por la Dian en la cual lo autoriza como
          autorretenedor.
        </p>
      </div>
      <app-upload-file
        (state)="showState($event, 5)"
        (getData)="getData($event, 'pdf6')"
      ></app-upload-file>
    </mat-card>
  </div>

  <div
    class="btn-container container div-bt"
    fxLayout
    fxLayout.xs="column"
    fxLayoutAlign="center"
    fxFlexAlign="stretch"
  >
    <button
      mat-button
      class="btn-primary"
      (click)="testPost()"
      [disabled]="registerForm.invalid || !questionValid"
    >
      Aceptar
    </button>

  
  </div>
</form>
