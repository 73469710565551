import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ApplicantJovenService } from 'src/app/services/applicant-joven.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'src/app/components/notification/notification.component';

const paef = "Febrero de 2021";
const pap = "Diciembre de 2020";
const ambos = "Diciembre de 2020 y Febrero de 2021";

@Component({
  selector: 'app-payroll-jovenes',
  templateUrl: './payroll-jovenes.component.html',
  styleUrls: ['./payroll-jovenes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PayrollJovenesComponent implements OnInit {

  payrollForm: FormGroup;

  maxPayrollFields = 22;

  // TODO: mapear fecha de la primera pantalla
  meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];


  dateobj: Date = new Date();
  mayoIndex = this.dateobj.getMonth();

  mesAnterior: Date;
  mesActual: string;
  messageMes: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private applicantService: ApplicantJovenService,
    private snackBar: MatSnackBar) {
     }

  ngOnInit() {
    
    this.payrollForm = this.fb.group({
      conditionsAccepted: false
    });
    
  }

  get payrollArray(): FormArray {
    return (this.payrollForm.controls.payrolls as FormArray);
  }
 
  save(): void {
      this.applicantService.previosPage = '/consentimiento-nuevosempleos';
      this.router.navigate(['/confirmar-nuevosempleos']);
    
  }

  openNotification(type: string, message: string, duration?: number) {
    this.snackBar.openFromComponent(NotificationComponent, {
      duration,
      data: { type, message },
      verticalPosition: 'top'
    });
  }
}
