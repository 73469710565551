import { Injectable } from "@angular/core";
import { ApplicantJoven } from "../models/applicantJoven.model";
import { Unavailability } from "../models/unavailability.model";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApplicantJovenFront } from "../models/applicantJoven-front.model";

@Injectable({
  providedIn: "root",
})
export class ApplicantJovenService {
  public previosPage = "";
  private urlBase = window.location.origin;
  // private urlBase = "https://qaabrircuenta.grupobancolombia.com";
  private url =
    "/com.bancolombia.subsidios.api/api/persistence/subCovid/jovenes";
  private urlContingencia =
    "/com.bancolombia.subsidios.api/api/persistence/subCovid/contingencia";
  // private urltest = this.urlBase + this.url;
  public applicant: ApplicantJovenFront = new ApplicantJovenFront();
  public unavailability: Unavailability = new Unavailability();

  private pdfs: Array<ArrayBuffer>;

  constructor(private http: HttpClient) {}

  getApplicant(): ApplicantJovenFront {
    return this.applicant;
  }

  setApplicant(applicant: ApplicantJovenFront) {
    this.applicant = applicant;
  }

  setPayrollData(payrolls: string, conditionsAccepted: boolean): void {
    this.applicant.pil1_planillamesant = payrolls;
  }

  setPdfFiles(pdfs) {
    this.pdfs = pdfs;
  }

  saveData(): Observable<any> {
    const formData: FormData = new FormData();
    this.pdfs
      .filter((pdf) => !!pdf)
      .forEach((pdfFile) =>
        formData.append("pdf", new Blob([pdfFile], { type: "application/pdf" }))
      );

    formData.append(
      "subCovidJovenesDto",
      new Blob([JSON.stringify(this.transformToBack(this.applicant))], {
        type: "application/json",
      })
    );

    return this.http.post(this.url, formData);
    // return this.http.post(this.urltest, formData);
  }

  transformToBack(applicant: ApplicantJovenFront): ApplicantJoven {
    return { ...applicant, numSolicitud: applicant.numSolicitud.numero };
  }

  saveDataContingencia(): Observable<any> {
    return this.http.post<any>(
      this.urlContingencia,
      this.transformToBack(this.applicant)
    );
  }

  setDataUnavailability(title: string, description: string, activate: boolean) {
    this.unavailability.title = title;
    this.unavailability.description = description;
    this.unavailability.activate = activate;
  }
}
