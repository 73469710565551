import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAddress]'
})
export class AddressDirective {

  regexValid = /^[a-zA-Z0-9 ]*$/;
  regexNotValid =/[`~!@#$%^&*()_|+\-=÷¿?;:'",.·`<>\{\}\[\]\\\/\s\n]/g;
  isValid: boolean;

  constructor(private el: ElementRef) { }


  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.isValid = new RegExp(this.regexValid).test(event.key);
    return this.isValid;
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {

      this.el.nativeElement.value = this.el.nativeElement.value.replace(this.regexNotValid, '');
      event.preventDefault();

    }, 100)
  }

}

