export class nuevosempleos {
  constructor() {}

  ipCliente: string;
  fechaEnvio: Date;
  origenRecepcion: string;
  buzonBanco: string;
  numRadicado: string;
  numSolicitud: number;
  nomEmpresa: string;
  tipoPerEmpresa: number;
  tipoIdEmpresa: string;
  numIdEmpresa: string;
  dirEmpresa: string;
  dptoEmpresa: number;
  ciudadEmpresa: number;
  telEmpresa: string;
  celEmpresa: number;
  emailEmpresa: string;
  codCiiuEmpresa: string;
  tipoCuentaBanco: string;
  numCuenta: number;
  entFinanciera: number;
  nomRepLegal: string;
  apellRepLegal: string;
  tipDocRepLegal: string;
  numDocRepLegal: string;
  emailRepLegal: string;
  celRepLegal: number;
  cum1: string;
  cum2: string;
  cum3: string;
  cum4: string;
  cum5: string;
  cum6: string;
  sus1: string;
  coop1: string;
  nat1: string;
  cum7: string;
  con2NumerosIdQuest: string;
  estadoEnvio: string;
  tipoDocPila: string;
  numDocPila: string;
  isRet: string;

}