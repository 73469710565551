import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { RouteGuardService } from "./guards/route-guard.service";
import { RouteGuardJovenService } from "./guards/route-guard-joven.service";
import { UnavailabilityMaintanceComponent } from "./components/unavailability-maintance/unavailability-maintance.component";
import { ApplyProgramJovenesComponent } from "./flujoJovenes/apply-program-jovenes/apply-program-jovenes.component";
import { InformacionJovenesComponent } from "./flujoJovenes/informacion-jovenes/informacion-jovenes.component";
import { RequerimentsJovenComponent } from "./flujoJovenes/requeriments-joven/requeriments-joven.component";
import { AttachedJovenesComponent } from "./flujoJovenes/attached-jovenes/attached-jovenes.component";
import { ConfirmJovenesComponent } from "./flujoJovenes/confirm-jovenes/confirm-jovenes.component";
import { FinishJovenesComponent } from "./flujoJovenes/finish-jovenes/finish-jovenes.component";
import { BannerJovenesComponent } from "./flujoJovenes/banner-jovenes/banner-jovenes.component";
import { PayrollJovenesComponent } from "./flujoJovenes/payroll-jovenes/payroll-jovenes.component";
const OPTS: ExtraOptions = {
  scrollPositionRestoration: "top",
  useHash: true,
  relativeLinkResolution: "legacy",
};
// ,{
//     scrollPositionRestoration: 'top',
//     useHash: true,
//     relativeLinkResolution: 'legacy'
// };
const routes: Routes = [
  {
    path: "",
    redirectTo: "/aplicar-programa-nuevosempleos",
    pathMatch: "full",
  },
  {
    path: "aplicar-programa-nuevosempleos",
    component: ApplyProgramJovenesComponent,
  },

  
  // {path: 'informacion', component: InformationComponent},
  {
    path: "informacion-nuevosempleos",
    component: InformacionJovenesComponent,
    canActivate: [RouteGuardJovenService],
  },
  // {path: 'informacion-nuevosempleos', component: InformacionJovenesComponent},
  // {path: 'requisitos-nuevosempleos', component: RequerimentsJovenComponent},
  {
    path: "requisitos-nuevosempleos",
    component: RequerimentsJovenComponent,
    canActivate: [RouteGuardJovenService],
  },
  // {path: 'planillas', component: PayrollComponent},
  {
    path: "consentimiento-nuevosempleos",
    component: PayrollJovenesComponent,
    canActivate: [RouteGuardJovenService],
  },
  // {path: 'consentimiento-nuevosempleos', component: PayrollJovenesComponent},
  // {path: 'confirmar', component: ConfirmComponent},
  // {path: 'confirmar-nuevosempleos', component: ConfirmJovenesComponent},
  {
    path: "confirmar-nuevosempleos",
    component: ConfirmJovenesComponent,
    canActivate: [RouteGuardJovenService],
  },
  // {path: 'anexos', component: AttachedComponent},
  // {path: 'anexos-nuevosempleos', component: AttachedJovenesComponent},
  {
    path: "anexos-nuevosempleos",
    component: AttachedJovenesComponent,
    canActivate: [RouteGuardJovenService],
  },
  
  // {path: 'finalizar', component: FinishComponent},
  // {path: 'finalizar-contingencia', component: FinishContingencyComponent},
  // {path: 'finalizar-nuevosempleos', component: FinishJovenesComponent},
  {
    path: "finalizar-nuevosempleos",
    component: FinishJovenesComponent,
    canActivate: [RouteGuardJovenService],
  },
  // {path: 'finalizar-contingencia', component: FinishContingencyComponent},
  { path: "unavailability", component: UnavailabilityMaintanceComponent },
  { path: "unavailability-nuevosempleos", component: BannerJovenesComponent },
  // {path: '**', redirectTo: 'aplicar-programa-nuevosempleos', pathMatch: 'full'}
  { path: "**", redirectTo: "aplicar-programa-nuevosempleos" },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, OPTS)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
