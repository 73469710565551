import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAlphaNumeric]'
})
export class AlphaNumericDirective {

  regexValid = '^[a-zA-Z0-9 \u00f1\u00d1]*$';
  regexNotValid =/[`~!@#$%^&*()_|+\-=÷¿?;:'",.·`<>\{\}\[\]\\\/\s\n]/g;
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef) { }


  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.isAlphaNumeric = new RegExp(this.regexValid).test(event.key);
    return this.isAlphaNumeric;
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {

      this.el.nativeElement.value = this.el.nativeElement.value.replace(this.regexNotValid, '');
      event.preventDefault();

    }, 100)
  }

}
