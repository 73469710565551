import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEmitterService } from './event-emitter.service';

@Component({
  selector: 'app-attached-error',
  templateUrl: './attachedError.component.html',
  styleUrls: ['./attachedError.component.scss']
})


export class AttachedErrorComponent implements OnInit {
  public redirect = 'https://www.grupobancolombia.com/personas/plan-apoyo-coronavirus/subsidio-pago-nomina';
  showExit: boolean;
  showButton: boolean;


  constructor(
    private dialogRef: MatDialogRef<AttachedErrorComponent>,
    private eventEmitterService: EventEmitterService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  dismiss(): void {
    this.dialogRef.close(null);
    this.eventEmitterService.nextData();
  }
}
