import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicantJovenService } from '../../services/applicant-joven.service'
import { DataService } from '../services/data.service';

@Component({
  selector: "app-banner-jovenes",
  templateUrl: "./banner-jovenes.component.html",
  styleUrls: ["./banner-jovenes.component.scss"],
})
export class BannerJovenesComponent implements OnInit{
  title = "El formulario que buscas se encuentra temporalmente deshabilitado.";
  descripcion = "Te invitamos a intentarlo más tarde.";

  constructor(
    private dataService: DataService,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.dataService.getDataSettings().subscribe(
      (res)=>{
        if(res.message[0].statusBanner===false){
          this.router.navigate(["/aplicar-programa-nuevosempleos"]);
        }
      },
      (err)=>{
      }
    )
  }
  

  redirectHome() {
    window.location.href =
      "https://www.bancolombia.com/personas/alternativas-financieras/subsidio-pago-nomina";
  }
}

