import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  attachedComp = new EventEmitter();
  subsVar: Subscription;
  constructor() { }
  nextData() {
    this.attachedComp.emit();
  }

}
