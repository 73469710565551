import { DataService } from './../services/data.service';
import { Component, OnInit } from '@angular/core';
import { ApplicantJovenService } from 'src/app/services/applicant-joven.service';
import { SpinnerService } from 'src/app/components/spinner-loading/spinner.service';

@Component({
  selector: 'app-finish-jovenes',
  templateUrl: './finish-jovenes.component.html',
  styleUrls: ['./finish-jovenes.component.scss']
})
export class FinishJovenesComponent implements OnInit {
  emailEmpresa: string;
  numeroSolicitud: number;
  tipoPersona: number;
  nombreEmpresa: string;
  numeroRadicado: string;
  numeroDocumento: string;
  tipoDocumento: string;
  hideNit: boolean;
  typeIdEmpresa;

  constructor(
    private applicantService: ApplicantJovenService,
    private spinerService: SpinnerService,
    private dataService:DataService
    ) { }

  ngOnInit() {
    this.applicantService.previosPage = null;
    this.spinerService.hide();
    this.hideNit = this.dataService.formNuevosEmpleos.tipoPerEmpresa === 5 && this.dataService.formNuevosEmpleos.tipoDocPila === 'NIT';
    this.emailEmpresa = this.dataService.formNuevosEmpleos.emailEmpresa;
    this.tipoPersona = this.dataService.formNuevosEmpleos.tipoPerEmpresa;
    this.numeroRadicado = this.dataService.nuevosEmpleosPost;
    this.nombreEmpresa = this.dataService.formNuevosEmpleos.nomEmpresa;
    this.numeroDocumento = this.hideNit ? this.dataService.formNuevosEmpleos.numDocPila : this.dataService.formNuevosEmpleos.numIdEmpresa;
    this.typeIdEmpresa = this.hideNit ? this.dataService.formNuevosEmpleos.tipoDocPila : this.dataService.formNuevosEmpleos.tipoIdEmpresa;
    this.validarDocumentos(this.typeIdEmpresa);
  }

  salir() {
    window.location.href =
      "https://www.bancolombia.com/personas/alternativas-financieras/subsidio-pago-nomina";
  }

  validarDocumentos(tipoDocumento) {

    switch (tipoDocumento) {
      case "CC": {
        this.tipoDocumento = "Cédula de Ciudadanía";
        break;
      }
      case "CE": {
        this.tipoDocumento = "Cédula de Extranjeria";
        break;
      }
      case "PA": {
        this.tipoDocumento = "Pasaporte";
        break;
      }
      case "SC": {
        this.tipoDocumento = "Salvoconducto de Permanencia";
        break;
      }
      case "CD": {
        this.tipoDocumento = "Carnet Diplomático";
        break;
      }
      case "NIT": {
        this.tipoDocumento = tipoDocumento;
      }
    }
  }

}
