 <div fxLayout fxLayoutAlign="space-between center">
    <p fxLayout fxLayoutAlign="center center">
      <span [ngSwitch]="data.type" class="icon-container">
        <ng-template ngSwitchCase="success">
          <span class="icon-check-circle success"></span>
        </ng-template>
        <ng-template ngSwitchCase="info">
          <span class="icon-info-circle info"></span>
        </ng-template>
        <ng-template ngSwitchCase="warn">
          <span class="icon-exclamation-circle warn"></span>
        </ng-template>
        <ng-template ngSwitchCase="danger">
          <span class="icon-times-circle danger"></span>
        </ng-template>
        <ng-template ngSwitchDefault>
        </ng-template>
      </span>
      <span id="errorToast">{{ data.message }}</span>
    </p>
  <a (click)="dismiss()" class="close icon-container" fxLayout fxLayoutAlign="center center">
    <span class="icon-times close-dark"></span>
  </a>
</div>
