<div class="content" *ngIf="showComponent">
  <div class="img__hero"></div>

  <mat-card class="example-card">
    <mat-card-header>
      <h3 class="font-dark-nt-24">¿Qué debes hacer?</h3>
    </mat-card-header>
    <mat-divider></mat-divider>
    <!-- <h3 class="title"> ¿Qué debes hacer?</h3> -->
    <div fxLayout="row" class="list-step" fxLayoutGap="20px">
      <div fxFlex>
        <mat-card-content class="style-mat-1">
          <img
            src="../../../assets/icons/fonts/quiz_FILL0_wght200_GRAD0_opsz48.svg"
            alt="icon-check"
            class="img-hero"
          />
          <h3><span> 1. Ingresa la información de tu empresa</span></h3>
          <p>Con esta podremos gestionar tu solicitud.</p>
        </mat-card-content>
      </div>
      <div fxFlex>
        <mat-card-content class="style-mat-1">
          <img
            src="../../../assets/icons/fonts/attach_file_add_FILL0_wght200_GRAD0_opsz48.svg"
            alt="icon-doc"
            class="img-hero"
          />
          <h3>2. Envía tu Solicitud</h3>
          <p>Te pediremos algunos documentos como soporte a tu solicitud.</p>
        </mat-card-content>
      </div>
      <div fxFlex>
        <mat-card-content class="style-mat-1">
          <img
            src="../../../assets/icons/fonts/pending_FILL0_wght200_GRAD0_opsz48.svg"
            alt="icon-mail"
            class="img-hero"
          />
          <h3>3. Espera nuestra respuesta</h3>
          <p>
            Nos comunicaremos contigo al correo electrónico que nos proporciones
            una vez hagamos las validaciones internas.
          </p>
        </mat-card-content>
      </div>
    </div>
    <!-- </mat-card-content> -->
  </mat-card>
  <form [formGroup]="registerForm">
    <!--AMBOS MARZO-->
    <mat-card class="example-card" *ngIf="true">
      <mat-card-header>
        <h3 class="font-dark-nt-24">Selecciona el mes de postulación</h3>
      </mat-card-header>
      <mat-divider></mat-divider>

      <mat-card-content class="style-mat-1">
        <div class="mb-10">
          <span class="font-dark-os-16">
            Seleccione el mes (nóminas) para el que se quiere postular de
            acuerdo con los siguientes ciclos de postulación, el mes elegido
            deberá coincidir con el mes diligenciado en el formulario físico. Si
            desea postularse a más de un mes tendrá que realizar una nueva
            postulación y diligenciar otro formulario. Recuerde que la nómina sobre 
            la que recibe el subsidio corresponde al mes al que se está postulando.
          </span>
        </div>
      </mat-card-content>

      <mat-card-content
        class="postulation"
        fxLayout="column"
        fxLayoutAlign="start start"
      >
        <div class="mt-10" fxFlex="100%">
          <section class="example-section" fxLayoutGap="20px">
            <!-- <ng-container *ngFor="let monthValue of monthsValue" > -->
            <mat-radio-group formControlName="mesPos">
              <ng-container *ngFor="let months of monthsPostulation">
                <mat-radio-button
                  *ngIf="months.month !== ''"
                  class="example-margin"
                  value="{{ months.value }}"
                >
                  {{ months.month }}
                </mat-radio-button>
              </ng-container>
            </mat-radio-group>
          </section>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-header>
        <h3 class="font-dark-nt-24">Información general de postulación</h3>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="style-mat-1">
        <div
          fxLayout="row wrap"
          fxLayoutAlign.xs="space-between"
          fxLayoutAlign="space-between center"
          class="questions-bc"
          fxLayoutGap.xs="0px"
        >
          <!-- Razon social-->
          <mat-form-field
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appAlphaNumeric
              type="text"
              minlength="1"
              maxlength="150"
              formControlName="razon"
              name="razon"
              id="razon"
              placeholder="Razón social de la empresa"
            />
            <span matSuffix class="icon-pencil"></span>
            <mat-error *ngIf="registerForm.controls['razon'].invalid"
              >{{ getErrorMessageRazon() }}
            </mat-error>
          </mat-form-field>

          <!-- tipo persona-->
          <mat-form-field
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <mat-label>Selecciona el tipo de persona</mat-label>
            <mat-select
              id="tipoPersona"
              name="tipoPersona"
              placeholder="Tipo persona"
              formControlName="tipoPersona"
            >
              <mat-option id="opt-1" value="1">Persona Jurídica</mat-option>
              <mat-option id="opt-3" value="2"
                >Consorcio o Unión Temporal</mat-option
              >
              <mat-option id="opt-4" value="3"
                >Patrimonio Autónomo Declarante</mat-option
              >
              <mat-option class="multiline-mat-option" id="opt-6" value="4">
                <div>Cooperativa de Trabajo Asociado</div>
                <div style="font-size: 10px;">(que se postuló en vigencia al decreto 1376 de 2023)</div>
              </mat-option>
              <mat-option id="opt-5" value="5">Persona Natural</mat-option>
            </mat-select>
            <span matSuffix class="icon-chevron-down"></span>
            <mat-error>
              <span> Campo requerido </span>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Persona Juridica-->
        <div
          fxLayoutGap="40px"
          *ngIf="
            registerForm.get('tipoPersona').value == 1 ||
            registerForm.get('tipoPersona').value == 2 ||
            registerForm.get('tipoPersona').value == 3 ||
            registerForm.get('tipoPersona').value == 4
          "
          class="questions-bc"
          fxLayoutGap.xs="0px"
        >
          <mat-form-field
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appNumberInputOnly
              type="text"
              minlength="6"
              maxlength="9"
              formControlName="nit"
              name="nit"
              id="nit"
              placeholder="Número de Nit sin dígito de verificación"
            />
            <mat-error *ngIf="registerForm.controls['nit'].invalid">{{
              getErrorMessage()
            }}</mat-error>
          </mat-form-field>
          <!--CONFIRMAR NIT-->
          <mat-form-field
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appNumberInputOnly
              type="text"
              minlength="6"
              maxlength="9"
              onCopy="return false"
              onDrag="return false"
              onDrop="return false"
              onPaste="return false"
              autocomplete="off"
              formControlName="nitConf"
              name="nitConf"
              id="nitConf"
              placeholder="Confirma el número de Nit sin dígito de verificación"
            />
            <mat-error *ngIf="registerForm.controls['nitConf'].invalid"
              >{{ getErrorMessageConf() }}
            </mat-error>
          </mat-form-field>
        </div>

        <div
          fxLayoutGap="40px"
          *ngIf="registerForm.get('tipoPersona').value == 2"
          class="questions-bc"
          fxLayoutGap.xs="0px"
        >
          <span class="font-dark-os-16"
            >Relaciona los números de identificación de las personas jurídicas o
            naturales que hacen parte del Consorcio o Unión temporal
          </span>
        </div>
        <!--Persona Natural-->
        <div
          fxLayoutGap="40px"
          *ngIf="registerForm.get('tipoPersona').value == 5"
          class="questions-bc"
          fxLayoutGap.xs="0px"
        >
          <mat-form-field
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <mat-select
              (selectionChange)="select($event.value)"
              id="tipoDocumento"
              required
              name="tipoDocumento"
              placeholder="Tipo de documento"
              formControlName="tipoDocumento"
            >
              <mat-option value="CC">Cédula de Ciudadanía</mat-option>
              <mat-option value="CE">Cédula de Extranjeria</mat-option>
              <mat-option value="PA">Pasaporte</mat-option>
              <mat-option value="SC">Salvoconducto de Permanencia </mat-option>
              <mat-option value="CD">Carnet Diplomático </mat-option>
              <mat-option value="NIT" translate="no">NIT</mat-option>
            </mat-select>
            <span matSuffix class="icon-chevron-down"></span>
            <mat-error>
              <span> Campo requerido </span>
            </mat-error>
          </mat-form-field>
        </div>

        <div
          *ngIf="
            registerForm.get('tipoPersona').value == 5 &&
            registerForm.get('tipoDocumento').value == 'NIT'
          "
          class="mt-4"
        >
          <span class="font-dark-os-16">
            Ingresa el número de Nit sin dígito de verificación con el que
            realizas los aportes a seguridad social desde Pila</span
          >
        </div>

        <!--Persona Natural-->
        <div
          fxLayoutGap="40px"
          *ngIf="registerForm.get('tipoPersona').value == 5"
          class="questions-bc"
          fxLayoutGap.xs="0px"
        >
          <mat-form-field
            *ngIf="registerForm.get('tipoDocumento').value != 'NIT'"
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appAlphaNumeric
              type="text"
              minlength="3"
              maxlength="10"
              formControlName="numeroDocumento"
              name="numeroDocumento"
              id="numeroDocumento"
              placeholder="Número de documento"
            />
            <span matSuffix class="far fa-pencil"></span>
            <mat-error>
              <span> Campo requerido </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="registerForm.get('tipoDocumento').value != 'NIT'"
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appAlphaNumeric
              type="text"
              minlength="3"
              maxlength="10"
              formControlName="numeroDocumentoConfirm"
              name="numeroDocumentoConfirm"
              id="numeroDocumentoConfirm"
              placeholder="Confirma el número de documento"
              onCopy="return false"
              onDrag="return false"
              onDrop="return false"
              onPaste="return false"
              autocomplete="off"
            />
            <span matSuffix class="far fa-pencil"></span>
            <mat-error
              *ngIf="registerForm.controls['numeroDocumentoConfirm'].invalid"
            >
              {{ getErrorMessageDocConfAlpha() }}
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="registerForm.get('tipoDocumento').value == 'NIT'"
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appNumberInputOnly
              type="text"
              minlength="3"
              maxlength="10"
              formControlName="numeroDocumento"
              name="numeroDocumento"
              id="numeroDocumento"
              placeholder="Número de Nit sin dígito de verificación"
            />
            <span matSuffix class="far fa-pencil"></span>
            <mat-error *ngIf="registerForm.controls['numeroDocumento'].invalid"
              >{{ getErrorMessageDoc() }}
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="registerForm.get('tipoDocumento').value == 'NIT'"
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appNumberInputOnly
              type="text"
              minlength="3"
              maxlength="10"
              formControlName="numeroDocumentoConfirm"
              name="numeroDocumentoConfirm"
              id="numeroDocumentoConfirm"
              placeholder="{{ placeNitConf }}"
              onCopy="return false"
              onDrag="return false"
              onDrop="return false"
              onPaste="return false"
              autocomplete="off"
            />
            <span matSuffix class="far fa-pencil"></span>
            <mat-error
              *ngIf="registerForm.controls['numeroDocumentoConfirm'].invalid"
            >
              {{ getErrorMessageDocConf() }}
            </mat-error>
          </mat-form-field>
        </div>
        <!--Consorcio-->
        <div [hidden]="registerForm.get('tipoPersona').value != 2">
          <div class="questions-bc">
            <div fxLayout="row wrap" fxFlex="100%">
              <div
                formArrayName="items"
                *ngFor="let item of itemsArray.controls; let i = index"
                fxFlex="50%"
                fxFlex.lt-sm="50%"
                fxFlex.xs="100%"
                fxFlex.lt-md="100%"
              >
                <mat-form-field fxFlex="100%" class="style-consorcio">
                  <input
                    matInput
                    appConsorcio
                    type="text"
                    minlength="3"
                    maxlength="10"
                    [formControlName]="i"
                    name="itemIdentificacion"
                    id="itemIdentificacion"
                    placeholder="Número de identificación"
                  />
                  <span matSuffix class="icon-pencil"></span>
                  <mat-error *ngIf="item.hasError('pattern')">
                    <span>Dato inválido</span>
                  </mat-error>
                  <mat-error *ngIf="item.hasError('required')">
                    <span>Campo requerido</span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div
          (click)="addItem(1)"
          class="style-add-item-button"
          [ngClass]="{ 'ds-field': countCS }"
          *ngIf="registerForm.get('tipoPersona').value == 2"
        >
          <em class="icon-plus-circle fa-2x style-icon-plus"></em
          ><span class="font-dark-nt-18"
            >Agregar otro número de identificación</span
          >
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          class="info-card mt-20"
          *ngIf="registerForm.get('tipoPersona').value == 5"
        >
          <img
            src="../../../assets/icons/fonts/help_FILL0_wght400_GRAD0_opsz48.svg"
            alt="help-pila"
            class="img-hero"
          />
          <p class="information-card">
            IMPORTANTE: Si es una persona natural que tiene más de un tipo de
            documento de identificación, debe señalar únicamente el tipo y
            número de identidad con el cual realiza sus pagos en PILA, está
            registrado en el RUES, el RUAF y cumple los demás requisitos del
            programa.
          </p>
        </div>

        <div
          *ngIf="
            registerForm.get('tipoPersona').value == 5 &&
            registerForm.get('tipoDocumento').value == 'NIT'
          "
          class="mt-4"
        >
          <span class="font-dark-os-16">
            Ingresa el tipo y número de documento asociado al producto
            financiero de Bancolombia con el que te postularás a este
            subsidio</span
          >
        </div>

        <!--Persona Natural Pila-->
        <div
          fxLayoutGap="40px"
          *ngIf="
            registerForm.get('tipoPersona').value == 5 &&
            registerForm.get('tipoDocumento').value == 'NIT'
          "
          class="questions-bc"
          fxLayoutGap.xs="0px"
        >
          <mat-form-field
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="50%"
          >
            <mat-select
              id="tipoDocumentoPila"
              required
              name="tipoDocumentoPila"
              placeholder="Tipo de documento"
              formControlName="tipoDocumentoPila"
            >
              <mat-option value="CC">Cédula de Ciudadanía</mat-option>
              <mat-option value="CE">Cédula de Extranjeria</mat-option>
              <mat-option value="PA">Pasaporte</mat-option>
              <mat-option value="SC">Salvoconducto de Permanencia </mat-option>
              <mat-option value="CD">Carnet Diplomático </mat-option>
            </mat-select>
            <span matSuffix class="icon-chevron-down"></span>
            <mat-error>
              <span> Campo requerido </span>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            *ngIf="registerForm.get('tipoDocumentoPila').value != 'PA'"
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appNumberInputOnly
              type="text"
              minlength="3"
              maxlength="10"
              formControlName="numeroDocumentoPila"
              name="numeroDocumentoPila"
              id="numeroDocumentoPila"
              placeholder="Número de documento"
            />
            <span matSuffix class="far fa-pencil"></span>
            <mat-error
              *ngIf="registerForm.controls['numeroDocumentoPila'].invalid"
            >
              {{ getErrorMessageDocPila() }}
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="registerForm.get('tipoDocumentoPila').value == 'PA'"
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appAlphaNumeric
              type="text"
              minlength="3"
              maxlength="10"
              formControlName="numeroDocumentoPila"
              name="numeroDocumentoPila"
              id="numeroDocumentoPila"
              placeholder="Número de documento"
            />
            <span matSuffix class="far fa-pencil"></span>
            <mat-error
              *ngIf="registerForm.controls['numeroDocumentoPila'].invalid"
            >
              {{ getErrorMessageDocPilaPA() }}
            </mat-error>
          </mat-form-field>
        </div>
        <!--Persona Natural Pila-->
        <div
          fxLayoutGap="40px"
          *ngIf="
            registerForm.get('tipoPersona').value == 5 &&
            registerForm.get('tipoDocumento').value == 'NIT'
          "
          class="questions-bc"
          fxLayoutGap.xs="0px"
        >
          <mat-form-field
            *ngIf="registerForm.get('tipoDocumentoPila').value !== 'PA'"
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appNumberInputOnly
              type="text"
              minlength="6"
              maxlength="10"
              onCopy="return false"
              onDrag="return false"
              onDrop="return false"
              onPaste="return false"
              autocomplete="off"
              formControlName="numeroDocumentoPilaConf"
              name="numeroDocumentoPilaConf"
              id="numeroDocumentoPilaConf"
              placeholder="Confirma el número de documento"
            />
            <mat-error
              *ngIf="registerForm.controls['numeroDocumentoPilaConf'].invalid"
            >
              {{ getErrorMessageDocPilaConf() }}
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="registerForm.get('tipoDocumentoPila').value == 'PA'"
            fxFlex="0 1 calc(50% - 15px)"
            fxFlex.lt-sm="50%"
            fxFlex.xs="100%"
            fxFlex.lt-md="100%"
          >
            <input
              matInput
              appAlphaNumeric
              type="text"
              minlength="6"
              maxlength="10"
              onCopy="return false"
              onDrag="return false"
              onDrop="return false"
              onPaste="return false"
              autocomplete="off"
              formControlName="numeroDocumentoPilaConf"
              name="numeroDocumentoPilaConf"
              id="numeroDocumentoPilaConf"
              placeholder="Confirma el número de documento"
            />
            <mat-error
              *ngIf="registerForm.controls['numeroDocumentoPilaConf'].invalid"
            >
              {{ getErrorMessageDocPilaConfPA() }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-header>
        <h3 class="font-dark-nt-24">Campos obligatorios</h3>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="style-mat-1">
        <div class="margin-xs mt-30">
          <mat-checkbox formControlName="chk1" class="example-margin">
            Certifico que la información diligenciada en el presente formulario
            es real y coincide completamente con la información de los
            formularios que serán impresos, digitalizados, enviados y que son
            exigidos por el Gobierno Nacional para la solicitud del beneficio
            del Programa Incentivo a la Creación y Permanencia de Nuevos Empleos Formales.
          </mat-checkbox>
        </div>
        <div class="margin-xs mt-30">
          <mat-checkbox formControlName="chk2" class="example-margin">
            Autorizo a Bancolombia para que realice el tratamiento de los datos
            y actualice las bases donde reposa mi información. Para esto,
            Bancolombia podrá solicitar, almacenar, conservar, consultar,
            procesar, compartir, informar, reportar, rectificar, modificar,
            actualizar, aclarar y reiterar la información recibida por el
            cliente.
          </mat-checkbox>
        </div>
        <div class="margin-xs mt-30">
          <mat-checkbox formControlName="chk4" class="example-margin mt-20"
            >En los términos establecidos por el artículo 79 de la Ley 2294 de 2023, 
            el Decreto 0533 del 29 de abril de 2024 y la Resolución 2200 del 7 de 
            junio de 2024, sus modificaciones y demás normas que regulen la materia, 
            presento bajo la gravedad del juramento, la postulación al Incentivo a la 
            Creación y Permanencia de Nuevos Empleos Formales bajo el cual, 
            previo cumplimiento de requisitos, se otorgará un aporte monetario de 
            naturaleza estatal, mensualmente y hasta por veinticuatro (24) veces por 
            cada empleado, con el objeto generar nuevos empleos formales en el país 
            mediante la contratación de nuevos trabajadores durante un término no 
            inferior a seis (06) meses.
          </mat-checkbox>
        </div>
      </mat-card-content>
    </mat-card>

    <div
      class="btn-container container"
      fxLayout
      fxLayout.xs="column"
      fxLayoutAlign="center"
      fxFlexAlign="stretch"
    >
      <button
        (click)="setData()"
        id="setData"
        [disabled]="!fieldChk1"
        mat-button
        class="btn-primary"
      >
        Aceptar
      </button>
    </div>

    <div
      class="btn-container container"
      fxLayout
      fxLayout.xs="column"
      fxLayoutAlign="center"
      fxFlexAlign="stretch"
    ></div>
  </form>
</div>
