
<nav class="navbar">
  <div  class="container">
    <div class="nav-centered">
      <p >Incentivo a la Creación y Permanencia de Nuevos Empleos Formales</p>
    </div>
    <img  class="logo" src="https://sucursalpersonas.transaccionesbancolombia.com/mua/images/logo.svg"/>
    
  </div>
  
</nav>