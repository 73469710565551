<div class="tx-c">
  <i class="icon-fal-fa-check-circle fa-4x cl-green"></i>
</div>

<p fxFlexAlign="center" id="solicitud-exitosa" class="font-dark-nt-50 title-02">
  ¡Hemos recibido tu solicitud!
</p>

<div fxLayout="row" fxLayoutAlign="center center">
  <p fxFlex="80" class="font-light-gray-nt-24 tx-c">
    Una vez finalicen nuestras validaciones internas, nos comunicaremos contigo
    a través del correo electrónico {{ emailEmpresa }}, recuerda guardar el
    número de radicado.
  </p>
</div>

<div fxLayout="row" fxLayoutAlign="center center">
  <mat-card
    fxFlex="75"
    fxFlexAlign="stretch"
    fxLayout="column"
    fxLayoutAlign="center start"
  >
    <span class="font-dark-nt-18">{{ nombreEmpresa }}</span>
    <span *ngIf="tipoPersona != 5" class="font-dark-nt-18">
      Número de Nit sin código de verificación:
      <span class="sp-1">{{ numeroDocumento }}</span>
    </span>
    <span *ngIf="tipoPersona == 5" class="font-dark-nt-18">
      Número de {{ tipoDocumento }} :
      <span class="sp-1">{{ numeroDocumento }}</span>
    </span>
    <span class="font-dark-nt-18">
      Número de radicado: <span class="sp-1">{{ numeroRadicado }}</span>
    </span>
  </mat-card>
</div>

<div fxLayoutAlign="center center">
  <button (click)="salir()" mat-button class="btn-primary btn-fin">
    SALIR
  </button>
</div>
