export class Solicitud {
    numero: number;
    mes: Date;
}

export class ApplicantFront {
    ipCliente: string;
    fechaEnvio: Date;
    origenRecepcion: string;
    buzonBanco: string;
    numRadicado: string;
    numFormulario: string;
    numSolicitud: Solicitud;
    nomEmpresa: string;
    tipoPerEmpresa: number;
    tipoIdEmpresa: string;
    numIdEmpresa: string;
    dirEmpresa: string;
    dptoEmpresa: number;
    ciudadEmpresa: number;
    telEmpresa: string;
    celEmpresa: number;
    emailEmpresa: string;
    codCiiuEmpresa: number;
    actividadesRes2162: string;
    beneficiarioFng: string;
    tipoCuentaBanco: string;
    numCuenta: number;
    entFinanciera: number;
    nomRepLegal: string;
    apellRepLegal: string;
    tipDocRepLegal: number;
    numDocRepLegal: string;
    emailRepLegal: string;
    celRepLegal: number;
    cum1Quest: string;
    cum2Quest: string;
    cum3Quest: string;
    cum4Quest: string;
    cum5Quest: string;
    cum6Quest: string;
    cum7Quest: string;
    con1EsconsorcioQuest: string;
    con2NumerosIdQuest: string;
    nat1Quest: string;
    nat2Quest: string;
    sus1: string;
    tipoDocPila:string;
    numDocPila:string;
    captchaToken: string;
    pil1_planillamesant: string;

    constructor() {
        this.numSolicitud = new Solicitud();
    }
}
