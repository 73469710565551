<div fxLayout="column">
    <div fxLayout="row-reverse">
        <a (click)="dismiss()" *ngIf="showExit">
            <span class="icon-times style-icon-close"></span>
        </a>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
        <!-- Icon -->
        <p style="margin-top: 30px">
            <span class="icon-times-circle danger style-icon-01"></span>
        </p>
        <h2 mat-dialog-title>¡Lo sentimos!</h2>
        <mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
            <p id="modalDescError">
                Alguno(s) de los documentos que has adjuntado como soporte no 
                pudieron gestionarse, estas pueden ser las posibles causas:
            </p>
            <ul>
                <li>Los documentos están protegidos con contraseña</li>
                <li>Versión de formato PDF no soportada (debe ser 1.4 a 1.7)</li>
                <li>Los documentos están corruptos</li>
            </ul>
            <p>Asegúrate de revisar cada uno de ellos y adjúntalos nuevamente.</p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button class="btn-primary mat-button" (click)="dismiss()">
                Adjuntar documentos
            </button>
        </mat-dialog-actions>
    </div>
</div>