<div fxLayout="column">
    <div fxLayout="row-reverse">
        <a (click)="dismiss()" *ngIf="showExit">
            <span class="icon-times style-icon-01"></span>
        </a>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">

        <!-- Icon -->
        <p [ngSwitch]="data.type" class="mt-30">
            <ng-template ngSwitchCase="success">
                <span class="fas fa-check-circle"></span>
            </ng-template>
            <ng-template ngSwitchCase="info">
                <span class="icon-info-circle style-icon-info"></span>
            </ng-template>
            <ng-template ngSwitchCase="warn">
                <span class="fas fa-exclamation-circle"></span>
            </ng-template>
            <ng-template ngSwitchCase="danger">
                <span class="icon-times-circle danger style-icon-danger"></span>
            </ng-template>
            <ng-template ngSwitchDefault>
            </ng-template>
        </p>

        <h2 mat-dialog-title *ngIf="!showTitle">¡Espera!</h2>
        <h2 mat-dialog-title *ngIf="showTitle">Ten presente</h2>

        <mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
            <p *ngIf="!showTitle" id="modalDescError" [innerHtml]="data.message"></p>
            <p *ngIf="showTitle" id="modalDescError">
                Para continuar con tu solicitud, verifica que tienes los formularios oficiales
                y actualizados, de lo contrario tu solicitud será rechazada. En caso de que
                necesites descargarlos
                <span class="link" (click)="irBancolombiaDocumentos()">
                    ingresa aquí</span> y selecciona el item “proceso para solicitar el subsidio”</p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button *ngIf="!showTitle" 
            [ngClass]="{'dialog': showButton}" 
            class="btn-primary mat-button"
                (click)="callSaveData()">
                {{ data.cta }}
            </button>
            <button *ngIf="showButton"
                [ngClass]="{'dialog': showButton, 'btn-secondary': !showTitle, 'btn-primary': showTitle}"
                mat-button
                class="mat-button" (click)="dismiss()">
                {{msgButton}}
            </button>
        </mat-dialog-actions>
    </div>
</div>