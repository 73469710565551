import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicantJovenService} from 'src/app/services/applicant-joven.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/components/spinner-loading/spinner.service';
import { NotificationComponent } from 'src/app/components/notification/notification.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RecaptchaComponent } from 'ng-recaptcha';
import { shareReplay, tap, switchMapTo } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { CatalogService } from 'src/app/services/catalog.service';

const emailregex: RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const TITTLE_MSG = 'Formulario de postulación al Incentivo a la Creación y Permanencia de Nuevos Empleos Formales';

@Component({
  selector: 'app-confirm-jovenes',
  templateUrl: './confirm-jovenes.component.html',
  styleUrls: ['./confirm-jovenes.component.scss']
})
export class ConfirmJovenesComponent implements OnInit {
  @ViewChild(RecaptchaComponent)
  private captchaComponent: RecaptchaComponent;
  public form: FormGroup;
  public isNatural: boolean;
  public showform: boolean = false;
  public nomEmpresa: string;
  public tipoIdEmpresa: string;
  public numIdEmpresa: string;
  public tipoCuentaBanco: string;
  public numCuenta: number;
  public emailEmpresa: string;
  public emailRepLegal: string;
  public isPassport: boolean;
  public activateContingencia: boolean = false;
  public captchaSuscribe: Subscription;
  public tittle: string;
  hideNit: boolean;
  public redirect = "https://www.grupobancolombia.com/personas/alivios-financieros/subsidio-pago-nomina";
  constructor(
    private applicantService: ApplicantJovenService,
    private catalogService: CatalogService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinerService: SpinnerService,
    private snackBar: MatSnackBar) {
    this.isNatural = applicantService.getApplicant().tipoPerEmpresa === 5;
    this.isPassport = applicantService.getApplicant().tipoIdEmpresa === 'PA';
    this.hideNit = this.isNatural && this.applicantService.getApplicant().tipoDocPila === 'NIT';
    const {
      nomEmpresa,
      tipoIdEmpresa,
      numIdEmpresa,
      tipoCuentaBanco,
      numCuenta,
      emailEmpresa,
      emailRepLegal,
      numDocPila, 
      tipoDocPila
     } = applicantService.getApplicant();
    this.nomEmpresa = nomEmpresa;
    this.tipoIdEmpresa = this.hideNit ? tipoDocPila : tipoIdEmpresa;
    this.numIdEmpresa = this.hideNit ? numDocPila: numIdEmpresa;  
    this.tipoCuentaBanco = tipoCuentaBanco;
    this.numCuenta = numCuenta;
    this.emailEmpresa = emailEmpresa;
    this.emailRepLegal = emailRepLegal;
    this.activateContingencia = this.catalogService.iscontingencyActive === true;
  }

  ngOnInit() {
    this.tittle = TITTLE_MSG;
    this.form = this.formBuilder.group({
      tipoIdEmpresa: [this.tipoIdEmpresa, [Validators.required]],
      numIdEmpresa: [this.numIdEmpresa],
      tipoCuentaBanco: [this.tipoCuentaBanco, [Validators.required]],
      numCuenta: [this.numCuenta, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      emailEmpresa: [this.emailEmpresa, Validators.pattern(emailregex)],
      emailRepLegal: [this.emailRepLegal, Validators.pattern(emailregex)],
    });
    this.validateFields();
    this.onChangesTipoDoc();

  }

  ngAfterViewInit() {
    if(this.activateContingencia){
     this.captchaComponent.siteKey =  this.catalogService.siteKey;
    }
  }

  irPortal(){
    window.location.href = this.redirect;
  }

  showEdit() {
    this.showform = !this.showform;
    this.form.get('tipoIdEmpresa').setValue(this.tipoIdEmpresa);
    this.form.get('numIdEmpresa').setValue(this.numIdEmpresa);
    this.form.get('tipoCuentaBanco').setValue(this.tipoCuentaBanco);
    this.form.get('numCuenta').setValue(this.numCuenta);
    this.form.get('emailEmpresa').setValue(this.emailEmpresa);
    this.form.get('emailRepLegal').setValue(this.emailRepLegal);
  }

  updateData() {
    this.showform = !this.showform;
    this.tipoIdEmpresa = this.form.get('tipoIdEmpresa').value;
    this.numIdEmpresa = this.form.get('numIdEmpresa').value;
    this.tipoCuentaBanco = this.form.get('tipoCuentaBanco').value;
    this.numCuenta = this.form.get('numCuenta').value;
    this.emailEmpresa = this.form.get('emailEmpresa').value;
    this.emailRepLegal = this.form.get('emailRepLegal').value;
  }

  getErrorMessage() {
    return this.form.controls['numCuenta'].hasError('required') ? 'Campo requerido' :
      this.form.controls['numCuenta'].hasError('pattern') ? 'Sólo se permiten números' : '';

  }

  save(): void {
    if (this.form.valid) {
      if (this.isNatural) {
        if(this.hideNit){
          this.applicantService.getApplicant().tipoDocPila = this.tipoIdEmpresa;
          this.applicantService.getApplicant().numDocPila = this.numIdEmpresa;
        }else{
          this.applicantService.getApplicant().tipoIdEmpresa = this.tipoIdEmpresa;
          this.applicantService.getApplicant().numIdEmpresa = this.numIdEmpresa;
        }
        this.applicantService.getApplicant().numDocRepLegal = this.numIdEmpresa;
      }else{
        this.applicantService.getApplicant().tipoIdEmpresa = this.tipoIdEmpresa;
        this.applicantService.getApplicant().numIdEmpresa = this.numIdEmpresa;
      }
      this.applicantService.getApplicant().tipoCuentaBanco = this.tipoCuentaBanco;
      this.applicantService.getApplicant().numCuenta = this.numCuenta;
      this.applicantService.getApplicant().emailEmpresa = this.emailEmpresa;
      this.applicantService.getApplicant().emailRepLegal = this.emailRepLegal;

      this.applicantService.previosPage = '/confirmar-nuevosempleos';
      if (this.activateContingencia) {
        this.saveData();
      }
      else {
        this.router.navigate(['/anexos-nuevosempleos']);
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  openNotification(type: string, message: string, duration?: number) {
    this.snackBar.openFromComponent(NotificationComponent, {
      duration,
      data: { type, message },
      verticalPosition: 'top'
    });
  }

  getTipoIdEmpresa(tipo: string): string {
    enum tiposId {
      CC = 'Cédula de Ciudadanía',
      CE = 'Cédula de Extranjeria',
      PA = 'Pasaporte',
      SC = 'Salvoconducto de Permanencia',
      CD = 'Carnet Diplomático',
      NIT = 'NIT'
    }
    return tiposId[tipo];
  }

  validateFields() {
    if (this.isNatural) {
      this.form.get('emailRepLegal').reset();
      this.form.get('emailRepLegal').setValidators(null);
      this.form.get('emailRepLegal').updateValueAndValidity();
    }
  }

  onChangesTipoDoc(): void {
    this.form.get('tipoIdEmpresa').valueChanges.subscribe(val => {
      if (val === 'PA') {
        this.isPassport = true;
        this.form.get('numIdEmpresa').setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]);
        this.form.get('numIdEmpresa').reset();
        this.form.get('numIdEmpresa').updateValueAndValidity();
      } else {
        this.isPassport = false;
        this.form.get('numIdEmpresa').setValidators([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]);
        this.form.get('numIdEmpresa').reset();
        this.form.get('numIdEmpresa').updateValueAndValidity();
      }
    })
  }

  getErrorMessageDoc() {
    return this.form.controls['numIdEmpresa'].hasError('required') ? 'Campo requerido' :
      this.form.controls['numIdEmpresa'].hasError('pattern') ? 'Sólo se permiten números' : '';
  }

  /**CAPTCHA */
  handleSaveData() {
    this.captchaSuscribe = this.captchaComponent.resolved.pipe(
      shareReplay(1),
      tap(token => this.setData(token)),
      switchMapTo(this.applicantService.saveDataContingencia())
    ).subscribe(result => {
      this.spinerService.hide();
      this.applicantService.applicant.numRadicado = result.incremental;
      this.router.navigate(['/finalizar-contingencia']);
    }, err => {
      this.spinerService.hide();
      this.openDialog(
        'danger',
        '¡Espera! Tenemos inconvenientes con el sistema pero estamos trabajando para darte una solución. ' +
        '¿Podrías realizar el proceso más tarde? Gracias.', 'error', 'Aceptar', true);

    });
  }

  setData(captcha: string): void {
    this.spinerService.show();
    this.applicantService.applicant.captchaToken = captcha;
  }

  saveData() {
    if (this.captchaSuscribe != null) {
      this.captchaSuscribe.unsubscribe();
    }
    this.handleSaveData();
    this.captchaComponent.execute();
  }

  openDialog(type: string, message: string, code: string, cta = 'Cerrar', isError) {
    this.dialog.open(DialogComponent, {
      data: {
        type,
        message,
        code,
        cta,
        isError
      },
      width: '400px'
    });
  }
}

