import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicantService } from '../services/applicant.service';

export const PAGES = new Map([
  ['/aplicar-programa', ''],
  ['/informacion', '/aplicar-programa'],
  ['/requisitos', '/informacion'],
  ['/planillas', '/requisitos'],
  ['/confirmar', '/planillas'],
  ['/anexos', '/confirmar'],
  ['/finalizar', '/anexos'],
  ['/finalizar-contingencia', '/confirmar']
]);

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {
  constructor( private router: Router, private applicantService: ApplicantService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {

    if (PAGES.get(state.url) !== this.applicantService.previosPage) {
      window.location.href = 'https://www.grupobancolombia.com/personas/plan-apoyo-coronavirus/subsidio-pago-nomina';
    } else {
        return true;
    }

  }
}
