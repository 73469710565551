import { Component, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { ApplicantJovenService } from "src/app/services/applicant-joven.service";
import { SpinnerService } from "src/app/components/spinner-loading/spinner.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { AttachedErrorComponent } from "src/app/components/attachedError/attachedError.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { RecaptchaComponent } from "ng-recaptcha";
import { tap, switchMap, first } from "rxjs/operators";
import { CatalogService } from "src/app/services/catalog.service";
import { EventEmitterService } from "src/app/components/attachedError/event-emitter.service";
import { UploadFileComponent } from "src/app/components/upload-file/upload-file.component";
import { Subscription } from "rxjs";
import { DataService } from "../services/data.service";

const uno = 1;
const cero = 0;
const dos = 2;
const tres = 3;
const cuatro = 4;
const cinco = 5;

const ICONS_STATE = [
  "icon-file-pdf",
  "icon-fas-fa-spinner",
  "icon-fal-fa-check-circle success",
];

const TITTLE =
  "Programa de Postulación al Incentivo a la Creación y Permanencia de Nuevos Empleos Formales";

const FILE_DESC =
  "Adjunta la última versión del formulario actualizada el 13 de Noviembre del 2020, de lo contrario este " +
  "documento no podrá ser procesado. Es importante que escanees y envíes las 4 páginas completas del formulario.";
const FILE_DESCPAP =
  "Adjunta la última versión del formulario actualizada el 24 de Agosto de 2020, de lo contrario este " +
  "documento no podrá ser procesado. Es importante que escanees y envíes todas las páginas completas del formulario.";

@Component({
  selector: "app-attached-jovenes",
  templateUrl: "./attached-jovenes.component.html",
  styleUrls: ["./attached-jovenes.component.scss"],
})
export class AttachedJovenesComponent implements OnInit {
  
  @ViewChildren(UploadFileComponent) files;

  public state = [0, 0, 0, 0, 0, 0];
  public isNatural = false;
  public isConsorcio = false;
  public isJuridica = false;
  public isPap = false;
  public registerForm: FormGroup;
  public mes = "";
  public captchaSuscribe: Subscription;
  public nombreArchivo: string;
  public descArchivo: string;
  public showAttachPap: boolean;
  public tittle: string;
  public isAutonomo: boolean;
  public d = new Date();
  public year;
  isCooperativa: boolean;
  isPatrimonio: boolean;
  tempCum7: any = [];
  cum7quest: any;
  questionValid: boolean;
  showRet: boolean;
  isRet:string;

  constructor(
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private applicantService: ApplicantJovenService,
    private spinerService: SpinnerService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dataService:DataService,
    private catalogService: CatalogService
  ) {
    const { tipoPerEmpresa } = this.applicantService.applicant;
    this.isNatural = tipoPerEmpresa === 5;
    this.isConsorcio = tipoPerEmpresa === 2;
    this.isJuridica = tipoPerEmpresa === 1;
    this.isPatrimonio = tipoPerEmpresa === 3;
    this.isCooperativa = tipoPerEmpresa === 4;
    this.year = this.d.getFullYear();
  }

  validarPreguntas() {
    this.tittle = TITTLE;
    if (this.isJuridica || this.isCooperativa) {
      this.registerForm.get("pdf3").reset();
      this.registerForm.get("pdf5").reset();
      this.registerForm.get("pdf3").setValidators(Validators.required);
      this.registerForm.get("pdf5").setValidators(Validators.required);
    } else if (this.isNatural || this.isPatrimonio) {
      this.registerForm.get("pdf5").reset();
      this.registerForm.get("pdf5").setValidators(Validators.required);
    } else {
      this.registerForm.get("pdf4").reset();
      this.registerForm.get("pdf5").reset();
      this.registerForm.get("pdf4").setValidators(Validators.required);
      this.registerForm.get("pdf5").setValidators(Validators.required);
    }
    this.registerForm.updateValueAndValidity();
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      pdf1: ["", Validators.required],
      pdf2: ["", Validators.required],
      pdf3: [""],
      pdf4: [""],
      pdf5: [""],
      pdf6: [""],
      op1: [""],
      op2: [""],
      op3: [""],
    });
    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar =
        this.eventEmitterService.attachedComp.subscribe((name: string) => {
          this.state = [0, 0, 0, 0, 0, 0];
          this.files.forEach((pdf) => {
            pdf.delete(pdf.input.nativeElement);
          });
        });
    }
    this.validarPreguntas();
  }


  showState(event, idDoc: number) {
    this.state[idDoc] = event;
  }

  getData(event, pdf) {
    this.registerForm.get(pdf).setValue(event);
  }

  getIcon(state: number): string {
    return ICONS_STATE[state];
  }

  setData(captcha: string): void {
    this.spinerService.show();
    this.applicantService.applicant.captchaToken = captcha;
    this.applicantService.setPdfFiles([
      this.registerForm.get("pdf1").value,
      this.registerForm.get("pdf2").value,
      this.registerForm.get("pdf3").value,
      this.registerForm.get("pdf4").value,
      this.registerForm.get("pdf5").value,
      this.registerForm.get("pdf6").value,
    ]);
  }

  handleSaveData() {
    this.setData("03ANYolqsvpdlAVeIkU1FOr0q");
     this.applicantService.saveData()
      .subscribe(
        (result) => {
          this.spinerService.hide();
          this.applicantService.applicant.numRadicado = result.incremental;
          this.applicantService.previosPage = "/anexos-nuevosempleos";
          this.router.navigate(["/finalizar-nuevosempleos"]);
        },
        (err) => {
          this.spinerService.hide();
          if (err.error.tipo === "WARN") {
            this.openDialogError();
          } else {
            this.openDialog(
              "danger",
              "¡Espera! Tenemos inconvenientes con el sistema pero estamos trabajando para darte una solución. " +
                "¿Podrías realizar el proceso más tarde? Gracias.",
              "error",
              "Aceptar",
              true
            );
          }
        }
      );
  }

  testPost():void{
    this.dataService.setDocuments([
      this.registerForm.get("pdf1").value,
      this.registerForm.get("pdf2").value,
      this.registerForm.get("pdf3").value,
      this.registerForm.get("pdf4").value,
      this.registerForm.get("pdf5").value,
      this.registerForm.get("pdf6").value,
    ]);
    this.dataService.setDataPost().subscribe(
      (res)=>{
        this.dataService.nuevosEmpleosPost=res.message.numRadicado
        this.router.navigate(["/finalizar-nuevosempleos"])
      },(err)=>{
         if (err.error.tipo === "WARN") {
           this.openDialogError();
         } else {
           this.openDialog(
             "danger",
             "¡Espera! Tenemos inconvenientes con el sistema pero estamos trabajando para darte una solución. " +
               "¿Podrías realizar el proceso más tarde? Gracias.",
             "error",
             "Aceptar",
             true
           );
         }
      }
    )

  }

  saveData() {
    this.applicantService.setPdfFiles([
      this.registerForm.get("pdf1").value,
      this.registerForm.get("pdf2").value,
      this.registerForm.get("pdf3").value,
      this.registerForm.get("pdf4").value,
      this.registerForm.get("pdf5").value,
      this.registerForm.get("pdf6").value,
    ]);
    this.handleSaveData();
  }

  retenet() {
    if (this.tempCum7.includes(dos)) {
      this.showRet = true;
      this.state[cinco] = cero;
      this.registerForm.get("pdf6").reset();
      this.registerForm.get("pdf6").setValidators(Validators.required);
      this.registerForm.get("pdf6").updateValueAndValidity();
    } else {
      this.showRet = false;
      this.state[cinco] = cero;
      this.registerForm.get("pdf6").reset();
      this.registerForm.get("pdf6").setValidators(null);
      this.registerForm.get("pdf6").updateValueAndValidity();
    
    }


  }

  getCheckboxValues(ev, data) {
    const index = this.tempCum7.indexOf(data, cero);
    if (ev.checked && data != tres) {
      if (this.tempCum7.includes(tres)) {
        let foo_object = tres; // Item to remove
        this.tempCum7 = this.tempCum7.filter((obj) => obj !== foo_object);
      }
     
      this.tempCum7.push(data);
      this.registerForm.get("op3").reset();
      
    } else if (data === tres && ev.checked) {
      this.tempCum7 = [];
      this.registerForm.get("op1").reset();
      this.registerForm.get("op2").reset();
      this.tempCum7.push(data);
      
      
    } else {
      this.tempCum7.splice(index, uno);
    }
    this.tempCum7.sort();
    let aux = this.tempCum7.filter((x) => !!x);
    if (aux.length > cero) {
      this.questionValid = true;
      this.cum7quest = aux.reduce((acc, current) => `${acc},${current}`);
      this.applicantService.getApplicant().cum7Quests = this.cum7quest;
      this.dataService.formNuevosEmpleos.cum7 = this.cum7quest;
    } else {
      this.questionValid = false;
    }
    if (this.registerForm.get("op2").value == true){
      this.isRet='SI'
      this.dataService.formNuevosEmpleos.isRet=this.isRet;
    }
    else{
      this.isRet="NO"
      this.dataService.formNuevosEmpleos.isRet=this.isRet;

    }
    
  }

  openDialog(
    type: string,
    message: string,
    code: string,
    cta = "Cerrar",
    isError
  ) {
    this.dialog.open(DialogComponent, {
      data: {
        type,
        message,
        code,
        cta,
        isError,
      },
      width: "400px",
    });
  }

  openDialogError() {
    this.dialog.open(AttachedErrorComponent, {
      data: {},
      width: "600px",
    });
  }
}
