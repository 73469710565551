import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '../notification/notification.component';

const MAX_SIZE_MGE_ERROR='¡Lo sentimos! El documento está muy pesado, revisa que no pese más de 2MB e inténtalo nuevamente.';
const TYPE_FILE_MGE_ERROR='¡Lo sentimos! El documento no está en formato PDF, revísalo e inténtalo nuevamente.';
const MAX_SIZE =2000000;
const TYPE_FILE_AUTHORIZED='application/pdf';
const SECONDS =5000;

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  @ViewChild('inputFile')
  public input: ElementRef;

  private file:File;
  public fileReader:FileReader;
  public readyState:number = 0;
  @Output() state = new EventEmitter();
  @Output() getData = new EventEmitter();

  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.fileReader = new FileReader();
    this.fileReader.onloadstart = (event)=>{
      this.emitState(this.fileReader.readyState);
    };
    this.fileReader.onprogress = (event)=>{
      this.emitState(this.fileReader.readyState);
    };
    this.fileReader.onload = (event)=>{
      this._handleReaderLoaded(event);
    };
    this.fileReader.onabort = (event)=>{
      this.emitState(0);
    };
  }

  _handlerFileSelect(event):void{
    if(event.target.files){
      this.file = event.target.files[0];
      if(MAX_SIZE<=this.file.size){
        this.delete(event.target);
        this.openNotification('danger',MAX_SIZE_MGE_ERROR,SECONDS);
      }else if(TYPE_FILE_AUTHORIZED!==this.file.type){
        this.delete(event.target);
        this.openNotification('danger',TYPE_FILE_MGE_ERROR,SECONDS);
      }else{
        this.fileReader.readAsArrayBuffer(this.file);
      }
    }
  }

  _handleReaderLoaded(readerEvt):void {
    const binaryString = readerEvt.target.result;
    this.emitData(binaryString);
    this.emitState(this.fileReader.readyState);
  }

  emitState(state:number):void{
    this.readyState=state;
    this.state.emit(this.readyState);
  }

  emitData(data:string):void{
    this.getData.emit(data);
  }

  delete(fileInputRef):void{
    fileInputRef.value = null;
    this.emitState(0);
    this.emitData('');
  }

  cancel():void{
    this.fileReader.abort();
    this.emitData('');
  }

  openNotification(type: string, message: string, duration?: number):void{
    this.snackBar.openFromComponent(NotificationComponent, {
      duration,
      data: { type, message },
      verticalPosition: 'top'
    });
  }

}
