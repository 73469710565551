export class Solicitud {
    numero: number;
    mes: Date;
}

export class ApplicantJovenFront {
    ipCliente: string;
    fechaEnvio: Date;
    origenRecepcion: string;
    buzonBanco: string;
    numRadicado: string;
    numFormulario: string;
    numSolicitud: Solicitud;
    nomEmpresa: string;
    tipoPerEmpresa: number;
    tipoIdEmpresa: string;
    numIdEmpresa: string;
    dirEmpresa: string;
    dptoEmpresa: number;
    ciudadEmpresa: number;
    telEmpresa: string;
    celEmpresa: number;
    emailEmpresa: string;
    codCiiuEmpresa: number;
    tipoCuentaBanco: string;
    numCuenta: number;
    entFinanciera: number;
    nomRepLegal: string;
    apellRepLegal: string;
    tipDocRepLegal: number;
    numDocRepLegal: string;
    emailRepLegal: string;
    celRepLegal: number;
    cum1Cum2Quests: string;
    cum4Cum5Quests: string;
    con1EsconsorcioQuest: string;
    con2NumerosIdQuest: string;
    per1MastresEmpleadosQuest: string;
    per2PepQuest: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    pil1_planillamesant: string;
    captchaToken: string;
    sus1: string;
    tipoDocPila: string;
    numDocPila:string;
    cum7Quests: string;
    coop1: string;
    cum1Quests: string;
    cum2Quests: string;
    cum3Quests: string;
    cum4Quests: string;
    cum5Quests: string;
    cum6Quests: string;
    nat1: string;

    constructor() {
        this.numSolicitud = new Solicitud();
    }
}
