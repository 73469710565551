import { Component, OnInit } from '@angular/core';
import { ApplicantService } from '../../services/applicant.service'

@Component({
  selector: 'app-unavailability-maintance',
  templateUrl: './unavailability-maintance.component.html',
  styleUrls: ['./unavailability-maintance.component.scss']
})
export class UnavailabilityMaintanceComponent implements OnInit {

  title = 'El formulario que buscas se encuentra temporalmente deshabilitado.';
  descripcion = 'Te invitamos a intentarlo más tarde.';

  constructor(private applicantService: ApplicantService) {
    this.applicantService.unavailability;
    if(this.applicantService.unavailability.activate){
      this.title = this.applicantService.unavailability.title;
      this.descripcion = this.applicantService.unavailability.description;
    }
  }

  ngOnInit() {
  }
  redirectHome(){
    window.location.href = 'https://www.grupobancolombia.com/personas/plan-apoyo-coronavirus/subsidio-pago-nomina';
  }
  

}
