import { DataService } from "./../services/data.service";
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { MustMatch } from "src/app/_helpers/must-match.validator";
import { ApplicantJovenService } from "src/app/services/applicant-joven.service";
import { Settings } from "../services/interfaces/settings";
import { Department } from "../services/interfaces/departments";
import { Dian } from "../services/interfaces/dian";
import { nuevosempleos } from "../services/interfaces/nuevosEmpleosClass";

const solDiez = 10;
const solOne = 2;
const solSeven = 7;
const solOnce = 11;
const solYear = 2021;
const regexPattern = /\-?\d*\.?\d{1,2}/;
const d = new Date();
const searchRegExp = /Ñ/g;
const replaceWith = "N";
const searchRegExp2 = /ñ/g;
const replaceWith2 = "n";

export interface MonthPostulation {
  month: string;
  value: number;
}

@Component({
  selector: "app-apply-program-jovenes",
  templateUrl: "./apply-program-jovenes.component.html",
  styleUrls: ["./apply-program-jovenes.component.scss"],
})
export class ApplyProgramJovenesComponent implements OnInit {
  myOptions = {
    placement: "right",
    autoPlacement: true,
    "show-delay": 0,
    "background-color": "#000",
    theme: "dark",
  };

  dateSolicitud = { numero: solOne, mes: new Date(solYear, solSeven) };
  solicitudes = [
    //  { numero: 8, mes: new Date(2020, 11) },
    { numero: solSeven, mes: new Date(solYear, solSeven) },
  ];

  public registerForm: FormGroup;
  fieldChk1: boolean;
  orderForm: FormGroup;
  items: FormArray;
  numeroIdentificacion = "0";
  countCS: boolean;
  count = 2;
  banner: any;
  showForm = false;
  activateBanner: boolean;
  primaActived: boolean;
  paefActived: boolean;
  showPrima = false;
  tittle: string;
  subtittle: string;
  placeNit: string;
  placeNitConf: string;
  messageMonth: boolean = false;
  pap: any;
  paef: any;
  flow: string;
  mesPos: number;

  // ************************ CuentaNueva ************************************
  settings: Settings[] = [];
  showComponent: boolean = false;
  months: string[] = [];
  monthsValue: number[] = [];
  monthsPostulation: MonthPostulation[] = [];
  dataPost: nuevosempleos;

  // ************************ Departments **********************************

  departments: Department[] = [];

  // ************************ Dian **********************************

  dian: Dian[] = [];

  // *********************** ************************************************

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private applicantService: ApplicantJovenService,
    private dataService: DataService,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group(
      {
        solicitud: [""],
        prima: [""],
        numeroPrimas: [""],
        razon: [
          "",
          [
            Validators.required,
            Validators.pattern(/^[a-zA-Z0-9 \u00f1\u00d1]*$/),
          ],
        ],
        tipoPersona: ["", Validators.required],
        nit: [""],
        nitConf: [""],
        tipoDocumento: [""],
        numeroDocumento: [""],
        numeroDocumentoConfirm: [""],
        itemIdentificacion2: [""],
        items: this.formBuilder.array([this.createItem(), this.createItem2()]),
        chk1: [false],
        chk2: [false],
        chk3: [false],
        chk4: [false],
        chk5: [false],
        chk6: [false],
        chk7: [false],
        chk8: [false],
        tipoDocumentoPila: [""],
        numeroDocumentoPila: [""],
        numeroDocumentoPilaConf: [""],
        paef: [""],
        pap: [""],
        mesPos: ["", [Validators.required]],
      },
      {
        validator: [
          MustMatch("nit", "nitConf"),
          MustMatch("numeroDocumento", "numeroDocumentoConfirm"),
          MustMatch("numeroDocumentoPila", "numeroDocumentoPilaConf"),
        ],
      }
    );

    this.onChangesTemp();
    this.onChanges();
    this.onChangesTipoDoc();
    this.validateWeb();
    this.onChangesPila();
    this.onChangesPilaDoc();

    //  **** Cuenta nueva ****

    // ******* Get Settings *******
    this.dataService.getDataSettings().subscribe(
      (res) => {
        this.settings = [...res.message];
        this.onReadSettings();
      },
      (err) => {
        this.router.navigate(["/unavailability-nuevosempleos"]);
      }
    );

    // ********* Get Departments ********
    this.dataService.getDataDepartments().subscribe(
      (res) => {
        this.departments = [...res.message];
        this.dataService.setDataDepts(this.departments);
      },
      (err) => {
        this.router.navigate(["/unavailability-nuevosempleos"]);
      }
    );
    //  ********** Get Dian *****************
    this.dataService.getDataDian().subscribe(
      (res) => {
        this.dian = [...res.message];
        this.dataService.setDataDian(this.dian);
      },
      (err) => {
        this.router.navigate(["/unavailability-nuevosempleos"]);
      }
    );

     this.dataPost = this.dataService.getformNuevosEmpleos;
  }

  ngAfterViewInit(): void {
    if (!this.isNovember()) {
      const numSolicitudInicial = 8;
      const fechaInicial = new Date(2020, 7);
      const numero = this.monthDiff(fechaInicial, d) + numSolicitudInicial;
      this.registerForm.get("solicitud").setValue({ numero, mes: d });
    }
  }

  monthDiff(from: Date, to: Date) {
    return (
      to.getMonth() -
      from.getMonth() +
      12 * (to.getFullYear() - from.getFullYear())
    );
  }

  isNovember(): boolean {
    return d.getMonth() == 10;
  }

  get itemsArray(): FormArray {
    return this.registerForm.controls.items as FormArray;
  }

  createItem(): FormControl {
    return this.formBuilder.control("", [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9]*$/),
    ]);
  }

  createItem2(): FormControl {
    return this.formBuilder.control("", [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9]*$/),
    ]);
  }
  createItem3(): FormControl {
    return this.formBuilder.control("", [Validators.pattern(/^[a-zA-Z0-9]*$/)]);
  }

  getErrorMessage() {
    return this.registerForm.controls.nit.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.nit.hasError("pattern")
      ? "Sólo se permiten números"
      : "";
  }
  getErrorMessageConf() {
    return this.registerForm.controls.nitConf.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.nitConf.hasError("pattern")
      ? "Sólo se permiten números"
      : this.registerForm.controls.nitConf.hasError("mustMatch")
      ? "El número de Nit no coincide con el ingresado en el campo anterior"
      : "";
  }

  getErrorMessageDocConf() {
    return this.registerForm.controls.numeroDocumentoConfirm.hasError(
      "required"
    )
      ? "Campo requerido"
      : this.registerForm.controls.numeroDocumentoConfirm.hasError("pattern")
      ? "Sólo se permiten números"
      : this.registerForm.controls.numeroDocumentoConfirm.hasError("mustMatch")
      ? "El número de documento no coincide con el ingresado en el campo anterior"
      : "";
  }

  getErrorMessageDocConfAlpha() {
    return this.registerForm.controls.numeroDocumentoConfirm.hasError(
      "required"
    )
      ? "Campo requerido"
      : this.registerForm.controls.numeroDocumentoConfirm.hasError("mustMatch")
      ? "El número de documento no coincide con el ingresado en el campo anterior"
      : "";
  }

  getErrorMessageDoc() {
    return this.registerForm.controls.numeroDocumento.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.numeroDocumento.hasError("pattern")
      ? "Sólo se permiten números"
      : "";
  }

  getErrorMessagePrima() {
    return this.registerForm.controls.numeroPrimas.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.numeroPrimas.hasError("pattern")
      ? "Sólo se permiten números"
      : "Sólo se permiten números";
  }

  getErrorMessageRazon() {
    return this.registerForm.controls.razon.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.razon.hasError("pattern")
      ? "Sólo se permiten números y letras"
      : "";
  }

  getErrorMessageDocPila() {
    return this.registerForm.controls.numeroDocumentoPila.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.numeroDocumentoPila.hasError("pattern")
      ? "Sólo se permiten números"
      : "";
  }
  getErrorMessageDocPilaPA() {
    return this.registerForm.controls.numeroDocumentoPila.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.numeroDocumentoPila.hasError("pattern")
      ? "Sólo se permiten y números letras"
      : "";
  }
  getErrorMessageDocPilaConf() {
    return this.registerForm.controls.numeroDocumentoPilaConf.hasError(
      "required"
    )
      ? "Campo requerido"
      : this.registerForm.controls.numeroDocumentoPilaConf.hasError("pattern")
      ? "Sólo se permiten números"
      : this.registerForm.controls.numeroDocumentoPilaConf.hasError("mustMatch")
      ? "El número de documento no coincide con el ingresado en el campo anterior"
      : "";
  }

  getErrorMessageDocPilaConfPA() {
    return this.registerForm.controls.numeroDocumentoPilaConf.hasError(
      "required"
    )
      ? "Campo requerido"
      : this.registerForm.controls.numeroDocumentoPilaConf.hasError("pattern")
      ? "Sólo se permiten números y letras"
      : this.registerForm.controls.numeroDocumentoPilaConf.hasError("mustMatch")
      ? "El número de documento no coincide con el ingresado en el campo anterior"
      : "";
  }

  addItem(data): void {
    if (!this.countCS) {
      this.registerForm.get("chk1").setValue(false);
      this.registerForm.get("chk2").setValue(false);
      this.items = this.registerForm.get("items") as FormArray;
      this.items.push(this.createItem3());
      this.count += 1;
      this.countCS = this.count >= 13 ? true : false;
    }
  }

  addItem2() {
    this.items = this.registerForm.get("items") as FormArray;
    this.items.push(this.createItem());
    this.items.push(this.createItem2());
  }

  setData() {
    this.dataService.formNuevosEmpleos.numSolicitud = this.dateSolicitud.numero;
    this.dataService.formNuevosEmpleos.nomEmpresa = this.registerForm
      .get("razon")
      .value.replace(searchRegExp, replaceWith)
      .replace(searchRegExp2, replaceWith2);
    this.dataService.formNuevosEmpleos.tipoPerEmpresa = Number(
      this.registerForm.get("tipoPersona").value
    );

    this.applicantService.getApplicant().numSolicitud = this.dateSolicitud;
    this.applicantService.getApplicant().nomEmpresa = this.registerForm
      .get("razon")
      .value.replace(searchRegExp, replaceWith)
      .replace(searchRegExp2, replaceWith2);
    this.applicantService.getApplicant().tipoPerEmpresa = Number(
      this.registerForm.get("tipoPersona").value
    );

    // nit o numeroDocumento
    if (this.registerForm.get("tipoPersona").value === "5") {
      if (this.registerForm.get("tipoDocumento").value === "NIT") {
        this.applicantService.getApplicant().tipoIdEmpresa =
          this.registerForm.get("tipoDocumentoPila").value;
        this.applicantService.getApplicant().numIdEmpresa =
          this.registerForm.get("numeroDocumentoPila").value;
        this.applicantService.getApplicant().tipoDocPila =
          this.registerForm.get("tipoDocumento").value;
        this.applicantService.getApplicant().numDocPila =
          this.registerForm.get("numeroDocumento").value;

        this.dataService.formNuevosEmpleos.tipoIdEmpresa =
          this.registerForm.get("tipoDocumentoPila").value;
        this.dataService.formNuevosEmpleos.numIdEmpresa = this.registerForm.get(
          "numeroDocumentoPila"
        ).value;
        this.dataService.formNuevosEmpleos.tipoDocPila =
          this.registerForm.get("tipoDocumento").value;
        this.dataService.formNuevosEmpleos.numDocPila =
          this.registerForm.get("numeroDocumento").value;
      } else {
        this.applicantService.getApplicant().tipoIdEmpresa =
          this.registerForm.get("tipoDocumento").value;
        this.applicantService.getApplicant().numIdEmpresa =
          this.registerForm.get("numeroDocumento").value;

        this.dataService.formNuevosEmpleos.tipoIdEmpresa =
          this.registerForm.get("tipoDocumento").value;
        this.dataService.formNuevosEmpleos.numIdEmpresa =
          this.registerForm.get("numeroDocumento").value;
      }
    } else {
      this.applicantService.getApplicant().numIdEmpresa = this.registerForm
        .get("nit")
        .value.replace(".", "");
      this.applicantService.getApplicant().tipoIdEmpresa = "NIT";

      this.dataService.formNuevosEmpleos.numIdEmpresa = this.registerForm
        .get("nit")
        .value.replace(".", "");
      this.dataService.formNuevosEmpleos.tipoIdEmpresa = "NIT";
    }
    if (this.registerForm.get("tipoPersona").value === "2") {
      // Valiar consorcio
      const payr = this.registerForm.get("items").value.filter((x) => !!x);
      this.numeroIdentificacion = payr.reduce(
        (acc, current) => `${acc}-${current}`
      );
      this.applicantService.getApplicant().con2NumerosIdQuest =
        this.numeroIdentificacion;

      this.dataService.formNuevosEmpleos.con2NumerosIdQuest =
        this.numeroIdentificacion;
    } else {
      this.applicantService.getApplicant().con2NumerosIdQuest = null;
      this.dataService.formNuevosEmpleos.con2NumerosIdQuest = null;
    }

    if (this.activateBanner) {
      this.applicantService.setDataUnavailability(
        this.banner[0].valor,
        this.banner[1].valor,
        this.activateBanner
      );
      this.router.navigate(["/unavailability-nuevosempleos"]);
    } else {
      this.openDialog(
        "info",
        "Para continuar con tu solicitud, verifica que tienes los formularios oficiales  y actualizados, de lo contrario tu " +
          'solicitud será rechazada. En caso de que necesites descargarlos <span class="link" (click)="irBancolombiaDocumentos();">ingresa ' +
          "aquí</span> y selecciona el item “proceso para solicitar el subsidio”",
        "infoMsg",
        "ENTENDIDO",
        false,
        "informacion-nuevosempleos",
        "aplicar-programa-nuevosempleos"
      );
    }
  }

  onChangesTipoDoc(): void {
    this.registerForm.get("tipoDocumento").valueChanges.subscribe((val) => {
      if (val === "PA") {
        this.registerForm
          .get("numeroDocumento")
          .setValidators([
            Validators.required,
            Validators.pattern(/^[a-zA-Z0-9]*$/),
          ]);
        this.registerForm
          .get("numeroDocumentoConfirm")
          .setValidators([
            Validators.required,
            Validators.pattern(/^[a-zA-Z0-9]*$/),
          ]);
      } else if (val === "CC" || val === "CE" || val === "SC" || val === "CD") {
        this.registerForm
          .get("numeroDocumento")
          .setValidators([
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          ]);
        this.registerForm
          .get("numeroDocumentoConfirm")
          .setValidators([
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          ]);
      }
      this.registerForm.get("numeroDocumento").reset();
      this.registerForm.get("numeroDocumento").updateValueAndValidity();
      this.registerForm.get("numeroDocumentoConfirm").reset();
      this.registerForm.get("numeroDocumentoConfirm").updateValueAndValidity();
    });
  }

  onChanges(): void {
    this.registerForm.valueChanges.subscribe((val) => {
      if (val.chk1 && val.chk2 && val.chk4 && this.registerForm.valid) {
        this.fieldChk1 = true;
      } else {
        this.fieldChk1 = false;
      }
      this.dateSolicitud.numero = Number(this.registerForm.get("mesPos").value);
    });
  }

  select(deviceValue) {
    this.registerForm.get("numeroDocumento").reset();
  }

  removeItem() {
    this.demoArray.removeAt(1);
    this.demoArray.removeAt(0);
  }

  get demoArray() {
    return this.registerForm.get("items") as FormArray;
  }

  getControls() {
    return (this.registerForm.get("controlName") as FormArray).controls;
  }

  onChangesPila(): void {
    this.registerForm.get("tipoDocumento").valueChanges.subscribe((tipo) => {
      if (tipo == "NIT") {
        this.placeNit = "Número de Nit sin dígito de verificación";
        this.placeNitConf =
          "Confirma el número de Nit sin dígito de verificación";
        this.registerForm
          .get("numeroDocumentoPila")
          .setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);
        this.registerForm
          .get("numeroDocumentoPilaConf")
          .setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);
        this.registerForm.get("numeroDocumentoPila").reset();
        this.registerForm.get("numeroDocumentoPilaConf").reset();
      } else {
        this.placeNit = "Número de documento";
        this.placeNitConf = "Confirma el número de documento";
        this.registerForm.get("numeroDocumentoPila").setValidators(null);
        this.registerForm.get("numeroDocumentoPilaConf").setValidators(null);
        this.registerForm.get("numeroDocumentoPila").reset();
        this.registerForm.get("numeroDocumentoPilaConf").reset();
      }
      this.registerForm.get("numeroDocumentoPila").updateValueAndValidity();
      this.registerForm.get("numeroDocumentoPilaConf").updateValueAndValidity();
    });
  }

  onChangesPilaDoc(): void {
    this.registerForm
      .get("tipoDocumentoPila")
      .valueChanges.subscribe((tipo) => {
        if (this.registerForm.get("tipoDocumentoPila").value != "PA") {
          this.registerForm
            .get("numeroDocumentoPila")
            .setValidators([
              Validators.required,
              Validators.pattern(/^[0-9]*$/),
            ]);
          this.registerForm
            .get("numeroDocumentoPilaConf")
            .setValidators([
              Validators.required,
              Validators.pattern(/^[0-9]*$/),
            ]);
        } else {
          this.registerForm
            .get("numeroDocumentoPila")
            .setValidators([
              Validators.required,
              Validators.pattern(/^[a-zA-Z0-9]*$/),
            ]);
          this.registerForm
            .get("numeroDocumentoPilaConf")
            .setValidators([
              Validators.required,
              Validators.pattern(/^[a-zA-Z0-9]*$/),
            ]);
        }
        this.registerForm.get("numeroDocumentoPila").reset();
        this.registerForm.get("numeroDocumentoPilaConf").reset();
        this.registerForm.get("numeroDocumentoPila").updateValueAndValidity();
        this.registerForm
          .get("numeroDocumentoPilaConf")
          .updateValueAndValidity();
      });
  }

  onChangesTemp(): void {
    this.registerForm.get("tipoPersona").valueChanges.subscribe((tipo) => {
      this.removeItem();
      if (tipo === "1" || tipo === "2" || tipo === "3" || tipo === "4") {
        if (tipo === "2") {
          this.addItem2();
        } else {
          this.removeItem();
        }
        this.registerForm
          .get("nit")
          .setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);
        this.registerForm
          .get("nitConf")
          .setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);
        this.registerForm.get("tipoDocumento").setValidators(null);
        this.registerForm.get("numeroDocumento").setValidators(null);
        this.registerForm.get("numeroDocumentoConfirm").setValidators(null);
        this.registerForm.get("tipoDocumentoPila").setValidators(null);
        this.registerForm.get("numeroDocumentoPila").setValidators(null);
        this.registerForm.get("numeroDocumentoPilaConf").setValidators(null);
        this.registerForm.get("tipoDocumentoPila").reset();
        this.registerForm.get("numeroDocumentoPila").reset();
        this.registerForm.get("numeroDocumentoPilaConf").reset();
        this.registerForm.get("nit").reset();
        this.registerForm.get("nitConf").reset();
        this.registerForm.get("tipoDocumento").reset();
        this.registerForm.get("numeroDocumento").reset();
        this.registerForm.get("numeroDocumentoConfirm").reset();
      } else if (tipo === "5") {
        this.removeItem();
        this.registerForm
          .get("tipoDocumento")
          .setValidators([Validators.required]);
        this.registerForm
          .get("numeroDocumento")
          .setValidators([
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          ]);
        this.registerForm
          .get("numeroDocumentoConfirm")
          .setValidators([
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          ]);
        this.registerForm.get("nit").setValidators(null);
        this.registerForm.get("nitConf").setValidators(null);
        this.registerForm.get("tipoDocumento").reset();
        this.registerForm.get("numeroDocumento").reset();
        this.registerForm.get("numeroDocumentoConfirm").reset();
        this.registerForm.get("nit").reset();
        this.registerForm.get("nitConf").reset();
      }
      this.registerForm.get("tipoDocumentoPila").updateValueAndValidity();
      this.registerForm.get("numeroDocumentoPila").updateValueAndValidity();
      this.registerForm.get("numeroDocumentoPilaConf").updateValueAndValidity();
      this.registerForm.get("nit").updateValueAndValidity();
      this.registerForm.get("nitConf").updateValueAndValidity();
      this.registerForm.get("tipoDocumento").updateValueAndValidity();
      this.registerForm.get("numeroDocumento").updateValueAndValidity();
      this.registerForm.get("numeroDocumentoConfirm").updateValueAndValidity();
    });
  }

  validateWeb() {
    const { browser, browser_version, device, os, os_version, userAgent } =
      this.deviceService.getDeviceInfo();
    this.validateBrowserVersion(browser, browser_version);
  }

  validateBrowserVersion(browser, browserVersion) {
    browser = browser.toLowerCase();
    browserVersion = +browserVersion.split(".")[0];
    // browser_version = 6;
    let message = "";
    if (browser === "chrome") {
      if (browserVersion < 80) {
        message =
          "Actualiza la versión de Google Chrome para que puedas tener una mejor experiencia en la solicitud de la cuenta " +
          "de ahorros.";
      }
    } else if (browser === "firefox") {
      if (browserVersion < 75) {
        message =
          "Actualiza la versión de Mozilla Firefox para que puedas tener una mejor experiencia en la solicitud " +
          "de la cuenta de ahorros.";
      }
    } else if (browser === "safari") {
      if (browserVersion < 12) {
        message =
          "Actualiza la versión de Safari para que puedas tener una mejor experiencia en la solicitud de la " +
          "cuenta de ahorros.";
      }
    } else if (browser.startsWith("ms-edge")) {
      if (browserVersion < 11) {
        message =
          "Actualiza la versión de Microsoft Edge para que puedas tener una mejor experiencia en durante la " +
          "solicitud de la cuenta de ahorros.";
      }
    } else if (
      browser !== "chrome" &&
      browser !== "firefox" &&
      browser !== "safari" &&
      !browser.startsWith("ms-edge")
    ) {
      message =
        "Te recomendamos utilizar Google Chrome o Firefox para que puedas tener una mejor experiencia.";
    }

    if (message !== "") {
      this.openDialog(
        "info",
        "Para tener una mejor experiencia, te recomendamos usar los siguientes navegadores: Google Chrome versión 80 en adelante, " +
          "Safari versión 12 en adelante y Firefox versión 75 en adelante.",
        "DEFAULT1",
        "ENTENDIDO",
        false,
        "informacion-nuevosempleos",
        "aplicar-programa-nuevosempleos"
      );
    }
  }

  openDialog(
    type: string,
    message: string,
    code: string,
    cta = "Cerrar",
    isError,
    nextUrl: string,
    prevUrl: string
  ) {
    this.dialog.open(DialogComponent, {
      data: {
        type,
        message,
        code,
        cta,
        isError,
        nextUrl,
        prevUrl,
      },
      width: "400px",
    });
  }

  // **************** Cuenta Nueva **********************************
  onReadSettings(): void {
    const settingsValue: Settings = this.settings[this.settings.length - 1];
    this.showComponent = !settingsValue.statusBanner;
    this.months.push(
      settingsValue.month1Name,
      settingsValue.month2Name,
      settingsValue.month3Name,
      settingsValue.month4Name
    );
    this.monthsValue.push(
      settingsValue.month1Value,
      settingsValue.month2Value,
      settingsValue.month3Value,
      settingsValue.month4Value
    );
    this.monthsPostulation.push(
      {
        month: this.months[0],
        value: this.monthsValue[0],
      },
      {
        month: this.months[1],
        value: this.monthsValue[1],
      },
      {
        month: this.months[2],
        value: this.monthsValue[2],
      },
      {
        month: this.months[3],
        value: this.monthsValue[3],
      }
    );
    if (settingsValue.statusBanner) {
      this.router.navigate(["/unavailability-nuevosempleos"]);
    }
  }
  // ********************************************************************
}
