<button *ngIf="readyState===0" mat-button (click)="inputFile.click()" 
class="btn-secondary style-001">
    ADJUNTAR
</button>
<input 
    #inputFile
    hidden 
    type="file" 
    accept=".pdf,application/pdf" 
    name="file" 
    id="file"
    (change)="_handlerFileSelect($event)">
<button *ngIf="readyState===1" mat-button (click)="cancel()" 
id="bt1" class="btn-secondary">
    CANCELAR
</button>
<button *ngIf="readyState===2" mat-icon-button id="bt2" (click)="delete(inputFile)">
    <span class="icon-far-fa-trash-alt fa-2x"></span>
</button>