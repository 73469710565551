<div class="box" *ngIf=" isLoading$ | async" >
</div>
<div class="overlay" *ngIf=" isLoading$ | async">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div >
    <img
      src="https://sucursalpersonas.transaccionesbancolombia.com/mua/images/logo.svg"
      alt="logo-banco"
      class="logo"
    />
  </div>
</div>

