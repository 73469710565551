<div fxLayout="column">
  <!-- <span class="font-light-black-os-14">{{ tittle }}</span> -->
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    class="mt-20"
  >
    <!-- <span class="icon-fal-fa-check-circle ft-z-24"></span> -->
    <img class="img-side" src="../../../assets/icons/fonts/fact_check_FILL0_wght200_GRAD0_opsz48.svg" alt="fact-check" >
    <span class="font-dark-nt-24">Confirma la información</span>
  </div>
  <!-- <span class="font-dark-nt-24 style-nom-empresa">{{nomEmpresa}}</span> -->
</div>
<form [formGroup]="form" class="container" fxLayout="column">
  <div
    fxLayout="row"
    fxLayoutGap="10px"
    class="info-card mt-20"
    *ngIf="hideNit"
  >
    <span class="font-black-nt-32">
      <i class="icon-info-circle"></i>
    </span>
    <p class="font-dark-os-16">
      Si necesitas ajustar algún dato de los que aquí se muestran, te
      recomendamos
      <span class="irPortal" (click)="irPortal()"
        >iniciar nuevamente tu solicitud.</span
      >
    </p>
  </div>
  <mat-card expanded="true" fxFlex="stretch">
    <mat-card-title>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span class="font-black-nt-20"> {{nomEmpresa}} </span>
        <span
          *ngIf="!showform && !hideNit"
          class="font-light-gray-os-sm-16"
          fxFlexAlign="stretch"
        >
          <a (click)="showEdit()" class="style-button-edit-1"> Editar </a>
        </span>
      </div>
    </mat-card-title>
    <mat-divider></mat-divider>
    <div *ngIf="!showform" fxLayout="row wrap">
      <div
        *ngIf="isNatural"
        fxLayout="column"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-description-1"
      >
        <span class="font-light-gray-os-14">Tipo de documento</span>
        <span class="font-dark-nt-20">{{
          getTipoIdEmpresa(tipoIdEmpresa)
        }}</span>
      </div>
      <div
        fxLayout="column"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-description-1"
      >
        <span class="font-light-gray-os-14">{{
          isNatural
            ? "Número de documento"
            : "Número de Nit sin código de verificación"
        }}</span>
        <span class="font-dark-nt-20">{{ numIdEmpresa }}</span>
      </div>
      <div
        fxLayout="column"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-description-1"
      >
        <span class="font-light-gray-os-14">Tipo de cuenta</span>
        <span class="font-dark-nt-20"
          >{{
            tipoCuentaBanco === "S" ? "Ahorros" : "Corriente"
          }}
          Bancolombia</span
        >
      </div>
      <div
        fxLayout="column"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-description-1"
      >
        <span class="font-light-gray-os-14">Número de cuenta</span>
        <span class="font-dark-nt-20">{{ numCuenta }}</span>
      </div>
      <div
        fxLayout="column"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-description-1"
      >
        <span class="font-light-gray-os-14"
          >Correo electrónico {{ isNatural ? "" : "de la empresa" }}</span
        >
        <span class="font-dark-nt-20">{{ emailEmpresa }}</span>
      </div>
      <div
        *ngIf="!isNatural"
        fxLayout="column"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-description-1"
      >
        <span class="font-light-gray-os-14"
          >Correo electrónico representante legal</span
        >
        <span class="font-dark-nt-20">{{ emailRepLegal }}</span>
      </div>
    </div>
    <div *ngIf="showform" fxLayout="row wrap">
      <mat-form-field
        *ngIf="isNatural"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-select-1"
      >
        <mat-select
          class="style-selecet-2"
          id="tipoIdEmpresa"
          required
          name="tipoIdEmpresa"
          placeholder="Tipo de documento"
          formControlName="tipoIdEmpresa"
        >
          <mat-option value="CC">Cédula de Ciudadanía</mat-option>
          <mat-option value="CE">Cédula de Extranjeria</mat-option>
          <mat-option value="PA">Pasaporte</mat-option>
          <mat-option value="SC">Salvoconducto de Permanencia</mat-option>
          <mat-option value="CD">Carnet Diplomático</mat-option>
        </mat-select>
        <span matSuffix class="icon-chevron-down"></span>
        <mat-error>
          <span> Campo requerido </span>
        </mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="!isNatural"
        class="font-dark-os-16 style-input-2"
        fxFlex="50%"
        fxFlex.xs="100%"
      >
        <mat-label>Número de Nit sin código de verificación</mat-label>
        <input
          matInput
          required
          appNumberInputOnly
          type="text"
          minlength="6"
          maxlength="9"
          formControlName="numIdEmpresa"
          id="numIdEmpresa"
          name="numIdEmpresa"
        />
        <span matSuffix class="icon-pencil"></span>
        <mat-error *ngIf="form.controls['numIdEmpresa'].invalid">{{
          getErrorMessageDoc()
        }}</mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="isNatural && !isPassport"
        class="font-dark-os-16 style-input-2"
        fxFlex="50%"
        fxFlex.xs="100%"
      >
        <mat-label>Número de documento</mat-label>
        <input
          matInput
          required
          appNumberInputOnly
          type="text"
          minlength="3"
          maxlength="10"
          formControlName="numIdEmpresa"
          id="numIdEmpresa"
          name="numIdEmpresa"
        />
        <span matSuffix class="icon-pencil"></span>
        <mat-error *ngIf="form.controls['numIdEmpresa'].invalid">{{
          getErrorMessageDoc()
        }}</mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="isNatural && isPassport"
        class="font-dark-os-16 style-input-2"
        fxFlex="50%"
        fxFlex.xs="100%"
      >
        <mat-label>Número de documento</mat-label>
        <input
          matInput
          required
          appAlphaNumeric
          type="text"
          minlength="3"
          maxlength="10"
          formControlName="numIdEmpresa"
          id="numIdEmpresa"
          name="numIdEmpresa"
        />
        <span matSuffix class="icon-pencil"></span>
        <mat-error *ngIf="form.controls['numIdEmpresa'].invalid">{{
          getErrorMessageDoc()
        }}</mat-error>
      </mat-form-field>

      <mat-form-field
        class="font-dark-os-16 style-input-2"
        fxFlex="50%"
        fxFlex.xs="100%"
      >
        <mat-label>Tipo de Cuenta</mat-label>
        <mat-select formControlName="tipoCuentaBanco" required>
          <mat-option value="S">Ahorros</mat-option>
          <mat-option value="D">Corriente</mat-option>
        </mat-select>
        <span matSuffix class="icon-chevron-down"></span>
      </mat-form-field>
      <mat-form-field
        class="font-dark-os-16 style-input-2"
        fxFlex="50%"
        fxFlex.xs="100%"
      >
        <mat-label>Número de Cuenta</mat-label>
        <input
          matInput
          required
          formControlName="numCuenta"
          appNumberInputOnly
          type="text"
          minlength="9"
          maxlength="11"
        />
        <span matSuffix class="icon-pencil"></span>
        <mat-error *ngIf="form.controls['numCuenta'].invalid">{{
          getErrorMessage()
        }}</mat-error>
      </mat-form-field>
      <mat-form-field
        class="font-dark-os-16 style-input-2"
        fxFlex="50%"
        fxFlex.xs="100%"
      >
        <mat-label
          >Correo electrónico {{ isNatural ? "" : "de la empresa" }}</mat-label
        >
        <input
          matInput
          required
          type="email"
          formControlName="emailEmpresa"
          id="emailEmpresa"
          name="emailEmpresa"
        />
        <span matSuffix class="icon-pencil"></span>
      </mat-form-field>
      <mat-form-field
        *ngIf="!isNatural"
        class="font-dark-os-16 style-input-2"
        fxFlex="50%"
        fxFlex.xs="100%"
      >
        <mat-label>Correo electrónico del representante legal</mat-label>
        <input
          matInput
          required
          type="email"
          formControlName="emailRepLegal"
          id="emailRepLegal"
          name="emailRepLegal"
        />
        <span matSuffix class="icon-pencil"></span>
      </mat-form-field>
    </div>
  </mat-card>
  <div
    *ngIf="!showform"
    class="mt-15"
    fxLayout="row"
    fxFlex="100%"
    fxLayoutAlign="center center"
  >
    <button fxFlex.xs="100%" (click)="save()" mat-button class="btn-primary">
      Continuar
    </button>
  </div>
  <div
    *ngIf="showform"
    class="mt-15"
    fxLayout="row"
    fxFlex="100%"
    fxLayoutAlign="center center"
  >
    <button
      fxFlex.xs="100%"
      (click)="updateData()"
      mat-button
      [disabled]="form.invalid"
      class="btn-primary"
    >
      Guardar
    </button>
  </div>

  <re-captcha *ngIf="activateContingencia" size="invisible"></re-captcha>
</form>
