import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner-loading',
  templateUrl: './spinner-loading.component.html',
  styleUrls: ['./spinner-loading.component.css']
})
export class SpinnerLoadingComponent  {
  public showSpinner = false;

  constructor(public spinnerSvc: SpinnerService) {}

  isLoading$ = this.spinnerSvc.isLoading$;

}
