import { Subject } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApplicantJovenService } from "src/app/services/applicant-joven.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationComponent } from "src/app/components/notification/notification.component";
import { CatalogService } from "src/app/services/catalog.service";
import { Observable, BehaviorSubject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Router } from "@angular/router";
import { MustMatch } from "src/app/_helpers/must-match.validator";
import { DataService } from "../services/data.service";
import { Dian } from "../services/interfaces/dian";
import { Department } from "../services/interfaces/departments";
import { nuevosempleos } from "../services/interfaces/nuevosEmpleosClass";

// eslint-disable-next-line max-len
const emailregex: RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const searchRegExp = /Ñ/g;
const replaceWith = "N";
const searchRegExp2 = /ñ/g;
const replaceWith2 = "n";

const FNG_MSG =
  "Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la " +
  "Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera " +
  "en la que tramitó dicho crédito. En cualquier caso, continúe con el diligenciamiento de los siguientes campos.";
const FNGPAP_MSG =
  "Recuerde que debe presentar su solicitud de subsidio PAP en la misma entidad financiera en la que " +
  "tramitó dicho crédito.";

export interface City {
  code: number[];
  name: string[];
}

export interface CodeCity {
  nameCity: string[];
  codeCity: string[];
}

@Component({
  selector: "app-informacion-jovenes",
  templateUrl: "./informacion-jovenes.component.html",
  styleUrls: ["./informacion-jovenes.component.scss"],
})
export class InformacionJovenesComponent implements OnInit {
  public registerForm: FormGroup;
  public mes: string;
  public esBeneficiario: boolean;
  public selectBeneficiario: boolean;
  public bancos = [];
  public emails = [];
  public esPA = false;
  public showExit = false;
  public verInfoRep: boolean;
  public ciudadDeptoFilter: Observable<any[]>;
  public ciiuFilter: Observable<any[]>;
  public fngMessage: string;
  public ciiuMes: boolean;
  public d = new Date();
  public year;
  placeCorreo: string;
  placeCorreoConf: string;

  // ******************** cuenta nueva *********************************

  dian: Dian[] = [];
  dataDept: Department[] = [];
  depts: string[] = [];
  city!: City;
  cities!: string[];
  filteredDept$: Observable<string[]>;
  filteredCity$: Observable<string[]>;
  filteredCiiu$: Observable<string[]>;
  codeCiiu: string[];
  descCiiu: string[];
  codeCity: string;
  codeDpto: string;
  dataCodeCity: CodeCity;
  dataPost: nuevosempleos;

  constructor(
    private formBuilder: FormBuilder,
    private applicantService: ApplicantJovenService,
    private snackBar: MatSnackBar,
    private catalogService: CatalogService,
    private router: Router,
    private dataService: DataService
  ) {
    this.year = this.d.getFullYear();
  }

  ngOnInit() {
    this.fngMessage =
      this.catalogService.isPapActive && !this.catalogService.isPaefActive
        ? FNGPAP_MSG
        : FNG_MSG;
    this.bancos = this.catalogService.catalogBancos;
    this.verInfoRep = this.applicantService.getApplicant().tipoPerEmpresa !== 5;
    this.registerForm = this.formBuilder.group(
      {
        direccion: [
          "",
          [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]*$/)],
        ],
        ciudadDepartamento: [""],
        city: ["", Validators.required],
        dept: ["", Validators.required],
        indicativo: ["", [Validators.required, Validators.pattern(/^[0-9]*$/)]],
        telefono: ["", [Validators.required, Validators.pattern(/^[0-9]*$/)]],
        ext: ["", Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
        movil: [
          "",
          [Validators.required, Validators.pattern(/^[3]-?([0-9]\d*)?$/)],
        ],
        correo: ["", Validators.pattern(emailregex)],
        correoConf: ["", [Validators.pattern(emailregex)]],
        ciiu: [""],
        ciiuDes: ["", Validators.required],
        esBeneficiario: [],
        bancoBeneficiario: [""],
        tipoCuentaBeneficiario: ["", Validators.required],
        numeroCuentaBeneficiario: [
          "",
          [Validators.required, Validators.pattern(/^[0-9]*$/)],
        ],
        numeroCuentaBeneficiarioConf: [
          "",
          [Validators.required, Validators.pattern(/^[0-9]*$/)],
        ],
        nombreRep: ["", Validators.pattern(/^[a-zA-Z \u00f1\u00d1]*$/)],
        apellidosRep: ["", Validators.pattern(/^[a-zA-Z \u00f1\u00d1]*$/)],
        tipoDocumentoRep: [""],
        numDocumentoRep: [""],
        numDocumentoRepConfirm: [""],
        correoRep: ["", Validators.pattern(emailregex)],
        celularRep: ["", Validators.pattern(/^[3]-?([0-9]\d*)?$/)],
        ciiuMes: [""],
      },
      {
        validator: [
          MustMatch("numeroCuentaBeneficiario", "numeroCuentaBeneficiarioConf"),
          MustMatch("numDocumentoRep", "numDocumentoRepConfirm"),
          MustMatch("correo", "correoConf"),
        ],
      }
    );
    this.onChangesTemp();
    this.onChangesTipoDoc();

    // ******** Cuenta nueva *******************
    this.dataService.dataDept$.subscribe(
      (res) => {
        this.dataDept = [...res];

        this.dataCodeCity = {
          nameCity: this.dataDept.map((data) => data.nombreMunicipio),
          codeCity: this.dataDept.map((data) =>
            data.codigoMunicipio.toString()
          ),
        };


        this.getDataDept(this.dataDept);
        this.filteredDept$ = this.registerForm.get("dept").valueChanges.pipe(
          startWith(""),
          map((value) => {
            return value ? this._filterVal(value || "") : this.depts.slice();
          })
        );
      },
      (err) => {
        this.router.navigate(["/unavailability-nuevosempleos"]);
      }
    );

    this.dataService.dataDian$.subscribe((value) => {
      this.dian = [...value];
      this.codeCiiu = this.dian.map((data) => data.clase.toString());
      this.descCiiu = this.dian.map((data) => data.description);
      this.filteredCiiu$ = this.registerForm.get("ciiu").valueChanges.pipe(
        startWith(""),
        map((value) => {
          return value ? this._filterDian(value || "") : this.codeCiiu.slice();
        })
      );
    });

    this.dataPost = this.dataService.getformNuevosEmpleos;
  }

  // ********************* Cuenta Nueva *******************

  getDataDept(data: Department[]): void {
    this.depts = data
      .map((depts) => depts.nombreDepto)
      .reduce((acc, act) => {
        if (!acc.includes(act)) {
          acc.push(act);
        }
        return acc;
      }, []);
    this.city = {
      code: data.map((data) => data.codigoMunicipio),
      name: data.map((data) => data.nombreMunicipio),
    };
  }

  private _filterVal(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.registerForm.patchValue({
      city: "",
    });
    this.cities = [
      ...this.dataDept
        .filter((citiesDept) =>
          citiesDept.nombreDepto.includes(value.toUpperCase())
        )
        .map((dept) => {
          return dept.nombreMunicipio;
        }),
    ];
    return this.depts.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private _filterCity(value: string, cities: string[]): string[] {
    const filterValue = value.toLowerCase();
    return cities.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private _getCodeCity(): void {
    const valueCity = this.registerForm.get("city").value.toUpperCase();


    if (valueCity.length > 3) {
      const codeCity = this.dataCodeCity.nameCity.findIndex(
        (data) => data === valueCity
      );
      const codeJob = this.dataCodeCity.codeCity[codeCity];

      const codefCity = codeJob.slice(-3);
      const codefDept =
        codeJob.length > 4 ? codeJob.slice(0, 2) : codeJob.slice(0, 1);
      this.codeCity = codefCity;
      this.codeDpto = codefDept;

     
    }
  }

  private _filterDian(value: string): string[] {
    const val = this.codeCiiu.filter((option) => option === value)[0];

    this.codeCiiu.filter((option) => {
      option.includes(value);
    });
    this.registerForm.patchValue({
      ciiuDes: this.descCiiu[this.codeCiiu.findIndex((code) => code === val)],
    });
    return this.codeCiiu.filter((option) => option.includes(value));
  }

  searchCity(value: string) {
    this.filteredCity$ = this.registerForm.get("city").valueChanges.pipe(
      startWith(""),
      map((value) => {
        return value ? this._filterCity(value || "", this.cities) : this.cities;
      })
    );
  }

  displayFnDept(option: string): string {
    return option ? option : "";
  }

  displayFnDian(option: string): string {
    return option ? option : "";
  }

  // *********************************************************************

  getErrorMessage() {
    return this.registerForm.controls.numeroCuentaBeneficiario.hasError(
      "required"
    )
      ? "Campo requerido"
      : this.registerForm.controls.numeroCuentaBeneficiario.hasError("pattern")
      ? "Sólo se permiten números"
      : "";
  }

  getErrorMessageConf() {
    return this.registerForm.controls.numeroCuentaBeneficiarioConf.hasError(
      "required"
    )
      ? "Campo requerido"
      : this.registerForm.controls.numeroCuentaBeneficiarioConf.hasError(
          "pattern"
        )
      ? "Sólo se permiten números"
      : this.registerForm.controls.numeroCuentaBeneficiarioConf.hasError(
          "mustMatch"
        )
      ? "El número de cuenta no coincide con el ingresado en el campo anterior"
      : "";
  }

  getErrorMessageCorreoConf() {
    return this.registerForm.controls.correoConf.hasError("required")
      ? "Campo requerido"
      : // this.registerForm.controls.correoConf.hasError('pattern') ? 'Sólo se permiten números' :
      this.registerForm.controls.correoConf.hasError("mustMatch")
      ? "El correo electrónico no coincide con el ingresado en el campo anterior"
      : "";
  }

  getErrorMessageTel() {
    return this.registerForm.controls.telefono.hasError("required")
      ? ""
      : this.registerForm.controls.telefono.hasError("pattern")
      ? "Sólo se permiten números"
      : "";
  }

  getErrorMessageCel() {
    return this.registerForm.controls.movil.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.movil.hasError("pattern")
      ? "El número debe iniciar en 3"
      : "";
  }

  getErrorMessageInd() {
    return this.registerForm.controls.indicativo.hasError("required")
      ? ""
      : this.registerForm.controls.indicativo.hasError("pattern")
      ? "Sólo se permiten números"
      : "";
  }

  getErrorMessageExt() {
    return this.registerForm.controls.ext.hasError("pattern")
      ? "Sólo se permiten números"
      : "";
  }

  getErrorMessageCC() {
    return this.registerForm.controls.numDocumentoRep.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.numDocumentoRep.hasError("pattern")
      ? "Sólo se permiten números"
      : "";
  }

  getErrorMessageCCConfirm() {
    return this.registerForm.controls.numDocumentoRepConfirm.hasError(
      "required"
    )
      ? "Campo requerido"
      : this.registerForm.controls.numDocumentoRepConfirm.hasError("pattern")
      ? "Sólo se permiten números"
      : this.registerForm.controls.numDocumentoRepConfirm.hasError("mustMatch")
      ? "El número de documento no coincide con el ingresado en el campo anterior"
      : "";
  }

  getErrorMessageAlpha() {
    return this.registerForm.controls.numDocumentoRep.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.numDocumentoRep.hasError("pattern")
      ? "Caracteres no permitidos"
      : "";
  }

  getErrorMessageAlphaConfirm() {
    return this.registerForm.controls.numDocumentoRepConfirm.hasError(
      "required"
    )
      ? "Campo requerido"
      : this.registerForm.controls.numDocumentoRepConfirm.hasError("mustMatch")
      ? "El número de documento no coincide con el ingresado en el campo anterior"
      : "";
  }

  getErrorMessageCelRep() {
    return this.registerForm.controls.celularRep.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.celularRep.hasError("pattern")
      ? "El número debe iniciar en 3"
      : "";
  }
  getErrorMessageDireccion() {
    return this.registerForm.controls.direccion.hasError("required")
      ? "Campo requerido"
      : this.registerForm.controls.direccion.hasError("pattern")
      ? "Recuerda que no debes ingresar caracteres especiales. Ej: # - , ."
      : "";
  }

  mostrarEsBeneficiario(value: boolean) {
    this.esBeneficiario = value;
    this.selectBeneficiario = true;
    this.setValidators(value);
  }

  setValidators(value: boolean) {
    this.registerForm.get("bancoBeneficiario").reset();
    if (value) {
      this.registerForm
        .get("bancoBeneficiario")
        .setValidators(Validators.required);
    } else {
      this.registerForm.get("bancoBeneficiario").setValidators(null);
    }
    this.registerForm.get("bancoBeneficiario").updateValueAndValidity();
  }

  onChangesTipoDoc(): void {
    this.registerForm.get("tipoDocumentoRep").valueChanges.subscribe((val) => {
      if (val === "PA") {
        this.registerForm
          .get("numDocumentoRep")
          .setValidators([
            Validators.required,
            ,
            Validators.pattern(/^[a-zA-Z0-9]*$/),
          ]);
        this.registerForm
          .get("numDocumentoRepConfirm")
          .setValidators([
            Validators.required,
            ,
            Validators.pattern(/^[a-zA-Z0-9]*$/),
          ]);
      } else {
        this.registerForm
          .get("numDocumentoRep")
          .setValidators([
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          ]);
        this.registerForm
          .get("numDocumentoRepConfirm")
          .setValidators([
            Validators.required,
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          ]);
      }
      this.registerForm.get("numDocumentoRep").reset();
      this.registerForm.get("numDocumentoRepConfirm").reset();
      this.registerForm.get("numDocumentoRep").updateValueAndValidity();
      this.registerForm.get("numDocumentoRepConfirm").updateValueAndValidity();
    });
  }

  openNotification(type: string, message: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      duration: 15000,
      data: { type, message },
      verticalPosition: "top",
    });
  }

  onChangesTemp(): void {
    this.registerForm
      .get("bancoBeneficiario")
      .valueChanges.subscribe((banco) => {
        this.showExit = false;
        this.snackBar.dismiss();
        if (banco !== "7" && banco) {
          this.showExit = true;
          const infoBanco = this.bancos.find((i) => i.id === banco).entidad;
          this.openNotification(
            "danger",
            "Ten presente: Debes tramitar el subsidio directamente con " +
              infoBanco +
              "."
          );
          this.registerForm
            .get("bancoBeneficiario")
            .setErrors({ incorrect: true });
        }
      });
    this.registerForm
      .get("tipoDocumentoRep")
      .valueChanges.subscribe((tipoDocumento) => {
        this.esPA = tipoDocumento === "PA";
        this.registerForm.get("numDocumentoRep").reset();
      });
  }

  displayFn(value?: any): string | undefined {
    return value ? value.departamentoCiudad : undefined;
  }

  displayFnCiiu(value?: any): string | undefined {
    return value ? value.ActividadEconomica : undefined;
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    const options = this.catalogService.catalogCiudadDepartamento;
    return options
      .filter(
        (option) =>
          option.departamentoCiudad.toLowerCase().indexOf(filterValue) > -1
      )
      .slice(0, 10);
  }

  private _filterCiiu(value: string): any[] {
    const filterValue = value.toLowerCase();
    const options = this.catalogService.catalogCIIU;
    return options
      .filter(
        (option) =>
          option.ActividadEconomica.toLowerCase().indexOf(filterValue) > -1 ||
          option.id.toLowerCase().indexOf(filterValue) > -1
      )
      .slice(0, 10);
  }

  validateChoiceAutocomplete(field: string, options: any[]): void {
    this.registerForm.get(field).valueChanges.subscribe((data) => {
      if (data) {
        const filterChoice = typeof data === "string" ? "" : data.id;
        const choice = options.find((option) => option.id === filterChoice);
        if (!choice) {
          this.registerForm.get(field).setErrors({ incorrect: true });
        }
      }
    });
  }

  validateChoiceAutocompleteCiuu(field: string, options: any[]): void {
    this.registerForm.get(field).valueChanges.subscribe((data) => {
      if (data) {
        this.ciiuMes = data.ActividadMes;
        this.registerForm.get("ciiuMes").reset();
        if (data.ActividadMes) {
          this.registerForm.get("ciiuMes").setValidators(null);
        } else {
          this.registerForm.get("ciiuMes").setValidators(null);
        }
        this.registerForm.get("ciiuMes").updateValueAndValidity();

        const filterChoice = typeof data === "string" ? "" : data.id;
        const choice = options.find((option) => option.id === filterChoice);
        if (!choice) {
          this.registerForm.get(field).setErrors({ incorrect: true });
        }
      }
    });
  }
  continuarInfoEmpresa() {
    this._getCodeCity();
    this.applicantService.getApplicant().dirEmpresa =
      this.registerForm.value.direccion
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    this.applicantService.getApplicant().dptoEmpresa = parseInt(this.codeDpto);
    this.applicantService.getApplicant().ciudadEmpresa = parseInt(
      this.codeCity
    );
    this.applicantService.getApplicant().buzonBanco = "";
    this.applicantService.getApplicant().telEmpresa =
      this.registerForm.value.indicativo +
      this.registerForm.value.telefono +
      " " +
      this.registerForm.value.ext;
    this.applicantService.getApplicant().celEmpresa =
      this.registerForm.value.movil;
    this.applicantService.getApplicant().emailEmpresa =
      this.registerForm.value.correo;
    this.applicantService.getApplicant().codCiiuEmpresa =
      this.registerForm.value.ciiu;

    this.dataService.formNuevosEmpleos.dirEmpresa =
      this.registerForm.value.direccion
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    this.dataService.formNuevosEmpleos.dptoEmpresa = parseInt(this.codeDpto);
    this.dataService.formNuevosEmpleos.ciudadEmpresa = parseInt(this.codeCity);
    this.dataService.formNuevosEmpleos.buzonBanco = "";
    this.dataService.formNuevosEmpleos.telEmpresa =
      this.registerForm.value.indicativo +
      this.registerForm.value.telefono +
      " " +
      this.registerForm.value.ext;
    this.dataService.formNuevosEmpleos.celEmpresa =
      this.registerForm.value.movil;
    this.dataService.formNuevosEmpleos.emailEmpresa =
      this.registerForm.value.correo;
    this.dataService.formNuevosEmpleos.codCiiuEmpresa =
      this.registerForm.value.ciiu;

    if (this.esBeneficiario) {
      this.applicantService.getApplicant().entFinanciera =
        this.registerForm.value.bancoBeneficiario;
    } else {
      this.applicantService.getApplicant().entFinanciera = 7;
      this.dataService.formNuevosEmpleos.entFinanciera = 7;
    }
    this.applicantService.getApplicant().tipoCuentaBanco =
      this.registerForm.value.tipoCuentaBeneficiario;
    this.dataService.formNuevosEmpleos.tipoCuentaBanco =
      this.registerForm.value.tipoCuentaBeneficiario;
    this.applicantService.getApplicant().numCuenta =
      this.registerForm.value.numeroCuentaBeneficiario;
    this.dataService.formNuevosEmpleos.numCuenta =
      this.registerForm.value.numeroCuentaBeneficiario;
    if (this.applicantService.getApplicant().tipoPerEmpresa !== 5) {
      this.applicantService.getApplicant().nomRepLegal =
        this.registerForm.value.nombreRep
          .replace(searchRegExp, replaceWith)
          .replace(searchRegExp2, replaceWith2);
      this.dataService.formNuevosEmpleos.nomRepLegal=
        this.registerForm.value.nombreRep.toLocaleLowerCase().trimEnd().trimStart();
      this.applicantService.getApplicant().apellRepLegal =
        this.registerForm.value.apellidosRep
          .replace(searchRegExp, replaceWith)
          .replace(searchRegExp2, replaceWith2);
      this.dataService.formNuevosEmpleos.apellRepLegal =
        this.registerForm.value.apellidosRep
          .toLocaleLowerCase()
          .trimEnd()
          .trimStart();
      this.applicantService.getApplicant().tipDocRepLegal =
        this.registerForm.value.tipoDocumentoRep;
      this.applicantService.getApplicant().numDocRepLegal =
        this.registerForm.value.numDocumentoRep;
      this.applicantService.getApplicant().emailRepLegal =
        this.registerForm.value.correoRep;
      this.applicantService.getApplicant().celRepLegal =
        this.registerForm.value.celularRep;
      this.dataService.formNuevosEmpleos.tipDocRepLegal =
        this.registerForm.value.tipoDocumentoRep;
      this.dataService.formNuevosEmpleos.numDocRepLegal =
        this.registerForm.value.numDocumentoRep;
      this.dataService.formNuevosEmpleos.emailRepLegal =
        this.registerForm.value.correoRep;
      this.dataService.formNuevosEmpleos.celRepLegal =
        this.registerForm.value.celularRep;
    } else {
      this.applicantService.getApplicant().numDocRepLegal =
        this.applicantService.getApplicant().numIdEmpresa;
      this.dataService.formNuevosEmpleos.numDocRepLegal =
        this.applicantService.getApplicant().numIdEmpresa;
    }
    this.applicantService.previosPage = "/informacion-nuevosempleos";
    this.router.navigate(["/requisitos-nuevosempleos"]);

  }

  exit() {
    window.location.href =
      "https://www.grupobancolombia.com/personas/plan-apoyo-coronavirus/subsidio-pago-nomina";
  }
}
