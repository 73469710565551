<div class="container" fxLayout="column">
	<span class="font-dark-nt-24">Empleados sobre los que se reconoce el subsidio</span>
	<p class="font-dark-os-16">
		En desarrollo de lo establecido en el artículo 79 de la Ley 2294 de 2023, el Decreto 0533 de 2024 y la Resolución 2200 
		del 7 de junio de 2024, sus modificaciones y demás normas que regulan la materia, los trabajadores adicionales sobre 
		los cuales se reconocerá el incentivo corresponden a la diferencia entre el número de cotizantes reportados en la 
		Planilla (PILA) por el postulante en mayo de 2023 y los reportados en el mes del apoyo que cumplan la permanencia de los 
		seis (6) meses y demás requisitos señalados en las disposiciones citadas. 
	</p>

	
	<span *ngIf = "false" class="font-dark-nt-24">Verificación de Requisitos</span>
	<p  *ngIf = "false" class="font-dark-os-16">
		Con el objetivo de que la UGPP pueda adelantar la verificación de requisitos señalada en el artículo 7 de la Resolución 2162 de 
		2020, modificado por el artículo 6 de la Resolución 2430 de 2021, certifico que se encuentran pagados los aportes de los 
		trabajadores objeto del subsidio.	
	</p>

	<form class="" [formGroup]="payrollForm">
		<div *ngIf = "false" formArrayName="payrolls" fxLayout="row wrap">
			<mat-form-field fxFlex="33%" fxFlex.lt-lg="50%" fxFlex.lt-md="100%" class="style-num-planilla"
				*ngFor="let payrollField of payrollArray.controls; let i = index">
				<mat-label>Ingrese el número de la planilla</mat-label>
				<input matInput [formControlName]="i" appNumberInputOnly type="text" minlength="5" maxlength="10" />
				<mat-error *ngIf="payrollField.hasError('minlength') || payrollField.hasError('maxlength')">
					El campo debe contener entre 5 y 10 caracteres
				</mat-error>
				<mat-error *ngIf="payrollField.hasError('required')">
					El campo es requerido
				</mat-error>
				<mat-error *ngIf="payrollField.hasError('pattern')">
					El campo debe ser numerico
				</mat-error>
			</mat-form-field>
		</div>

		<!-- <a  *ngIf = "false" class="font-dark-os-16 style-001" [ngClass]="{'ds-field': payrollArray.length>=22}"
			 (click)="addPayrollField()"><em class="icon-plus-circle fa-2x style-add-1"></em>
			<span class="font-dark-nt-18" class="style-add-2" [ngClass]="{'ds-field': payrollArray.length>=22}">Agregar otro número de Planilla</span>

		</a> -->

		<span>
			<mat-checkbox class="font-dark-os-16" formControlName="conditionsAccepted">
				De acuerdo con lo establecido en el artículo79 de la Ley 2294 de 2023, el Decreto 0533 de 2024 y la Resolución 2200 
				del 7 de junio de 2024, expreso mi consentimiento y certifico el cumplimiento de los requisitos establecidos en la 
				norma, los cuales he declarado bajo la gravedad del juramento y asumo la responsabilidad derivada de cualquier 
				inexactitud o incumplimiento de las disposiciones pertinentes.
			</mat-checkbox>
		</span>

		
	</form>

	
	<div class="btn-container container style-button-next" 
	fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxFlexAlign="stretch">
		<button mat-button class="btn-primary" (click)="save()"
			[disabled]="!(payrollForm.valid && payrollForm.value.conditionsAccepted)">Continuar</button>
	</div>
</div>