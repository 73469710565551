import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { NotificationComponent } from './components/notification/notification.component';
import { MaterialModule } from './shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CatalogService } from './services/catalog.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NumberInputOnlyDirective } from './directive/number-input-only.directive';
import { AlphaNumericDirective } from './directive/alpha-numeric.directive';
import { CelularInputOnlyDirective } from './directive/celular-input-only.service';
import { SpinnerService } from './components/spinner-loading/spinner.service';
import { SpinnerLoadingComponent } from './components/spinner-loading/spinner-loading.component';
import { AddressDirective } from './directive/address.directive';
import { RouteGuardService } from './guards/route-guard.service';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ConsorcioDirective } from './directive/consorcio.directive';
import { OnlyLettersDirective } from './directive/only-letters.directive';
import { DialogComponent } from './components/dialog/dialog.component';
import {  AttachedErrorComponent } from './components/attachedError/attachedError.component';
import { UnavailabilityMaintanceComponent } from './components/unavailability-maintance/unavailability-maintance.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { ApplicantService } from './services/applicant.service';
import { ApplicantJovenService } from './services/applicant-joven.service';
import { EventEmitterService} from './components/attachedError/event-emitter.service';
import localeEsCo from '@angular/common/locales/es-CO';
import { registerLocaleData } from '@angular/common';
import { ApplyProgramJovenesComponent } from './flujoJovenes/apply-program-jovenes/apply-program-jovenes.component';
import { InformacionJovenesComponent } from './flujoJovenes/informacion-jovenes/informacion-jovenes.component';
import { RequerimentsJovenComponent } from './flujoJovenes/requeriments-joven/requeriments-joven.component';
import { AttachedJovenesComponent } from './flujoJovenes/attached-jovenes/attached-jovenes.component';
import { ConfirmJovenesComponent } from './flujoJovenes/confirm-jovenes/confirm-jovenes.component';
import { FinishJovenesComponent } from './flujoJovenes/finish-jovenes/finish-jovenes.component';
import { RouteGuardJovenService } from './guards/route-guard-joven.service';
import { BannerJovenesComponent } from './flujoJovenes/banner-jovenes/banner-jovenes.component';
import { PayrollJovenesComponent } from './flujoJovenes/payroll-jovenes/payroll-jovenes.component';
import { DataServiceInterceptor } from './flujoJovenes/services/interceptor/data-service.interceptor';

registerLocaleData(localeEsCo);

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    NotificationComponent,
    NumberInputOnlyDirective,
    AlphaNumericDirective,
    SpinnerLoadingComponent,
    AddressDirective,
    CelularInputOnlyDirective,
    SpinnerLoadingComponent,
    ConsorcioDirective,
    OnlyLettersDirective,
    DialogComponent,
    UnavailabilityMaintanceComponent,
    UploadFileComponent,
    AttachedErrorComponent,
    ApplyProgramJovenesComponent,
    InformacionJovenesComponent,
    RequerimentsJovenComponent,
    AttachedJovenesComponent,
    ConfirmJovenesComponent,
    FinishJovenesComponent,
    BannerJovenesComponent,
    PayrollJovenesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialModule,
    HttpClientModule,
    TooltipModule,
    RecaptchaModule,
  ],
  providers: [
    CatalogService,
    SpinnerService,
    RouteGuardService,
    ApplicantService,
    EventEmitterService,
    ApplicantJovenService,
    RouteGuardJovenService,
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "es",
    },
    {
      provide: LOCALE_ID,
      useValue: "es-CO",
    },
    { provide: HTTP_INTERCEPTORS, useClass: DataServiceInterceptor, multi:true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
