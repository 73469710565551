import { Component, OnInit } from "@angular/core";
import { ApplicantJovenService } from "src/app/services/applicant-joven.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationComponent } from "src/app/components/notification/notification.component";
import { Router } from "@angular/router";
import { DataService } from "../services/data.service";

const MSGERROR =
  "¡Lo sentimos! De acuerdo a tus respuestas, no aplicas para obtener el beneficio del Programa de Incentivo a la Creación y Permanencia de Nuevos Empleos Formales " +
  'Verifica tus respuestas para continuar con el proceso o haz clic en "Salir".';
const SI = "SI";
const NO = "NO";

@Component({
  selector: "app-requeriments-joven",
  templateUrl: "./requeriments-joven.component.html",
  styleUrls: ["./requeriments-joven.component.scss"],
})
export class RequerimentsJovenComponent implements OnInit {
  form: FormGroup;
  mes: string;
  isNatural: boolean;
  isConsorcio: boolean;
  isJuridica: boolean;
  isCooperativa: boolean;
  showExit = false;
  showPatronal = false;
  items: FormArray;
  countCS: boolean;
  count = 1;
  warningMsg: string;
  numResolucion: string;
  msgEmployees: string;
  msgError: string;
  showPatronalCard: boolean;
  public d = new Date();
  public year;

  constructor(
    private applicantService: ApplicantJovenService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private dataService: DataService,
  ) {
    this.isCooperativa = applicantService.applicant.tipoPerEmpresa === 4;
    this.isJuridica = applicantService.applicant.tipoPerEmpresa === 1;
    this.isConsorcio = applicantService.applicant.tipoPerEmpresa === 2;
    this.isNatural = applicantService.applicant.tipoPerEmpresa === 5;
    this.year = this.d.getFullYear();
  }

  ngOnInit() {
    this.showPatronalCard = true;
    this.msgError = MSGERROR;
    this.form = this.formBuilder.group({
      cum1: ["", [Validators.required]],
      cum2: ["", [Validators.required]],
      cum3: ["", [Validators.required]],
      cum4: ["", [Validators.required]],
      cum5: ["", [Validators.required]],
      cum6: ["", [Validators.required]],
      coop1: ["", [Validators.required]],
      nat1: ["", [Validators.required]],
      items: this.formBuilder.array([this.createItem()]),
    });

    this.validateFields();
  }

  createItem(): FormControl {
    return this.formBuilder.control("", [Validators.pattern(/^[a-zA-Z0-9]*$/)]);
  }

  get itemsArray(): FormArray {
    return this.form.controls.items as FormArray;
  }

  addItem(): void {
    if (!this.countCS) {
      this.items = this.form.get("items") as FormArray;
      this.items.push(this.createItem());
      this.count += 1;
      this.countCS = this.count >= 13 ? true : false;
    }
  }
  choosen(event) {
    this.showExit = false;
  }

  validateFields() {
  if (this.isNatural) {
      this.form.get("coop1").reset();
      this.form.get("nat1").reset();
      this.form.get("coop1").setValidators(null);
      this.form.get("nat1").setValidators(Validators.required);
      this.form.updateValueAndValidity();
    }else{
      this.form.get("coop1").reset();
      this.form.get("nat1").reset();
      this.form.get("coop1").setValidators(null);
      this.form.get("nat1").setValidators(null);
      this.form.updateValueAndValidity();
    }
  }

  next() {
    const { value } = this.form;
    const { cum1, cum2, cum3, cum4, cum5, cum6 } = value;
    this.applicantService.getApplicant().cum1Quests = cum1;
    this.applicantService.getApplicant().cum2Quests = cum2;
    this.applicantService.getApplicant().cum3Quests = cum3;
    this.applicantService.getApplicant().cum4Quests = cum4;
    this.applicantService.getApplicant().cum5Quests = cum5;
    this.applicantService.getApplicant().cum6Quests = cum6;

    this.dataService.formNuevosEmpleos.cum1 = cum1;
    this.dataService.formNuevosEmpleos.cum2 = cum2;
    this.dataService.formNuevosEmpleos.cum3 = cum3;
    this.dataService.formNuevosEmpleos.cum4 = cum4;
    this.dataService.formNuevosEmpleos.cum5 = cum5;
    this.dataService.formNuevosEmpleos.cum6 = cum6;

    const patronal = this.form.get("items").value.filter((x) => !!x);
    if (patronal.length > 0) {
      this.applicantService.getApplicant().sus1 = patronal.reduce(
        (acc, current) => `${acc}-${current}`
      );
      this.dataService.formNuevosEmpleos.sus1 = patronal.reduce(
        (acc, current) => `${acc}-${current}`
      );
    } else {
      this.applicantService.getApplicant().sus1 = null;
      this.dataService.formNuevosEmpleos.sus1 = null;
    }
    if (this.isCooperativa) {
      this.validateCooperativa(value);
    } else if (this.isNatural) {
      this.validateNatural(value);
    } else {
      this.validateOther(value);
    }
  }

  validateCooperativa(value: any) {
    const { cum1, cum2, cum3, cum4, cum5, coop1 } = value;
    this.applicantService.getApplicant().coop1 = coop1;
    this.dataService.formNuevosEmpleos.coop1 = coop1;
    
    if (
      cum1 === SI &&
      cum2 === NO &&
      cum3 === NO &&
      cum4 === SI &&
      cum5 === SI 
      //&&
      // coop1 == SI
    ) {
      this.showExit = false;
      this.navegateRoute();
    } else {
      this.showExit = true;
      this.openNotification("danger", this.msgError);
    }
  }

  validateNatural(value: any) {
    const { cum1, cum2, cum3, cum4, cum5, nat1 } = value;
    this.applicantService.getApplicant().nat1 = nat1;
    this.dataService.formNuevosEmpleos.nat1 = nat1;
    if (
      cum1 === SI &&
      cum2 === NO &&
      cum3 === NO &&
      cum4 == SI &&
      cum5 === SI &&
      nat1 == NO
    ) {
      this.showExit = false;
      this.navegateRoute();
    } else {
      this.showExit = true;
      this.openNotification("danger", this.msgError);
    }
  }

  validateOther(value: any) {
    const { cum1, cum2, cum3, cum4, cum5 } = value;
    if (
      cum1 === SI &&
      cum2 === NO &&
      cum3 === NO &&
      cum4 == SI &&
      cum5 === SI
    ) {
      this.showExit = false;
      this.navegateRoute();
    } else {
      this.showExit = true;
      this.openNotification("danger", this.msgError);
    }
  }

  exit() {
    window.location.href =
      "https://www.grupobancolombia.com/personas/plan-apoyo-coronavirus/subsidio-pago-nomina";
  }

  navegateRoute() {
    this.applicantService.previosPage = "/requisitos-nuevosempleos";
    this.router.navigate(["/consentimiento-nuevosempleos"]);
  }

  openNotification(type: string, message: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      duration: 7000,
      data: { type, message },
      verticalPosition: "top",
    });
  }

  changeState(value) {
    this.showPatronal = value === "SI" ? true : false;
  }
}
