<form class="container" fxLayout="column" [formGroup]="registerForm">
  <!-- <span class="font-light-gray-os-16 style-date">Solicitud {{mes}} de {{year}}</span> -->
  <span class="font-dark-nt-24">Ingresa la siguiente información</span>
  <p class="font-dark-os-16">
    La información que ingreses en el siguiente formulario se usará solo para
    realizar la solicitud, no se actualizarán los datos registrados en el banco.
  </p>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <div class="font-dark-os-16 center-span">
        <img
          class="img-side"
          src="../../../assets/icons/fonts/contacts_FILL0_wght200_GRAD0_opsz48.svg"
          alt="companys"
        />
        <h4>Información de la empresa</h4>
      </div>
    </mat-expansion-panel-header>
    <div fxLayout="row wrap">
      

      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Departamento</mat-label>
        <input
          matInput
          required
          formControlName="dept"
          placeholder="ej. Antioquia"
          name="dept"
          type="text"
          (ngModelChange)="searchCity($event)"
          [matAutocomplete]="deptAc"
          id="dept"
        />
        <mat-autocomplete autoActiveFirstOption #deptAc="matAutocomplete" >
          <mat-option
            *ngFor="let dept of filteredDept$   | async " [value]="dept | titlecase"
          >
            {{ dept | titlecase }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Ciudad</mat-label>
        <input
          matInput
          required
          formControlName="city"
          placeholder="ej. Medellin"
          name="city"
          type="text"
          [matAutocomplete]="cityAC"
          id="city"
        />
        <mat-autocomplete
          #cityAC="matAutocomplete"
          [displayWith]="displayFnDept"
        >
          <mat-option
            *ngFor="let city of filteredCity$ | async "
            [value]="city | titlecase"
          >
            {{ city | titlecase }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div fxFlex="100%">
        <mat-form-field
          class="font-dark-os-16"
          fxFlex="50%"
          fxFlex.xs="100%"
          class="style-input-1"
        >
          <mat-label>Dirección</mat-label>
          <input
            matInput
            required
            formControlName="direccion"
            appAddress
            type="text"
            minlength="1"
            maxlength="150"
            id="direccion"
            name="direccion"
          />
          <span></span>
          <mat-error *ngIf="registerForm.controls['direccion'].invalid"
            >{{ getErrorMessageDireccion() }}
          </mat-error>
          <mat-hint
            >Ingresa la dirección sin caracteres especiales, Ej: Carrera 48 N 26
            85</mat-hint
          >
        </mat-form-field>
      </div>

      <div
        fxFlex="50%"
        fxFlex.xs="100%"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="style-input-1"
      >
        <mat-form-field class="font-dark-os-16" fxFlex="22%">
          <mat-label>Indicativo</mat-label>
          <input
            matInput
            required
            formControlName="indicativo"
            appNumberInputOnly
            type="text"
            minlength="1"
            maxlength="1"
            id="indicativo"
            name="indicativo"
          />
          <mat-error *ngIf="registerForm.controls['indicativo'].invalid"
            >{{ getErrorMessageInd() }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="font-dark-os-16" fxFlex="50%">
          <mat-label>Teléfono fijo</mat-label>
          <input
            matInput
            required
            formControlName="telefono"
            appNumberInputOnly
            type="text"
            minlength="7"
            maxlength="7"
            id="telefono"
            name="telefono"
          />
          <mat-error *ngIf="registerForm.controls['telefono'].invalid">{{
            getErrorMessageTel()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="font-dark-os-16" fxFlex="22%">
          <mat-label>Ext.</mat-label>
          <input
            matInput
            formControlName="ext"
            appNumberInputOnly
            type="text"
            maxlength="6"
            id="ext"
            name="ext"
          />
          <mat-error *ngIf="registerForm.controls['ext'].invalid">{{
            getErrorMessageExt()
          }}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1 mt-12"
      >
        <mat-label>Teléfono móvil</mat-label>
        <input
          matInput
          appCelularInputOnly
          required
          formControlName="movil"
          type="text"
          minlength="10"
          maxlength="10"
          id="movil"
          name="movil"
        />
        <mat-error *ngIf="registerForm.controls['movil'].invalid">{{
          getErrorMessageCel()
        }}</mat-error>
      </mat-form-field>
      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Correo electrónico</mat-label>
        <input
          matInput
          required
          formControlName="correo"
          type="text"
          id="correo"
        />
        <mat-error> Formato no valido </mat-error>
      </mat-form-field>
      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <input
          matInput
          type="text"
          onCopy="return false"
          onDrag="return false"
          onDrop="return false"
          onPaste="return false"
          autocomplete="off"
          formControlName="correoConf"
          name="correoConf"
          id="correoConf"
          placeholder="Confirma el correo electrónico"
        />
        <mat-error *ngIf="registerForm.controls['correoConf'].invalid"
          >{{ getErrorMessageCorreoConf() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="50%"
        class="style-input-1"
      >
        <mat-label>Código asociado a la actividad económica</mat-label>
        <input
          matInput
          required
          formControlName="ciiu"
          name="ciiu"
          type="text"
          [matAutocomplete]="ciiuAC"
          id="ciiu"
        />
        <mat-autocomplete
          #ciiuAC="matAutocomplete"
          [displayWith]="displayFnDian"
        >
          <mat-option
            *ngFor="let code of filteredCiiu$ | async"
            [value]="code"
          >
            {{ code }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
       <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="50%"
        class="style-input-1"
      >
        <mat-label>Descripción de la Actividad económica</mat-label>
        <input
          matInput
          formControlName="ciiuDes"
          name="ciiuDes"
          type="text"
          id="ciiuDes"
          readonly="readonly"
        />
      </mat-form-field>

      <!--CIUU MES-->
      <p fxFlex="100%" *ngIf="false">
        ¿Ha venido desarrollando dicha actividad, como actividad principal, al
        menos desde el 06 de mayo de 2020 y hasta el mes anterior a la presente
        postulación de forma ininterrumpida?
      </p>
      <mat-radio-group
        *ngIf="false"
        fxFlex="15%"
        fxFlex.xs="100%"
        fxLayoutAlign="space-between center"
        fxLayoutAlign.xs="space-around center"
        class="pad-bot-10"
        formControlName="ciiuMes"
      >
        <mat-radio-button value="SI">Si</mat-radio-button>
        <mat-radio-button value="NO">No</mat-radio-button>
      </mat-radio-group>


      <div fxFlex="100%" fxLayout="row wrap">
        <p fxFlex="100%" *ngIf="esBeneficiario">{{ fngMessage }}</p>
        <mat-form-field
          class="font-dark-os-16"
          *ngIf="esBeneficiario"
          fxFlex="50%"
          fxFlex.xs="100%"
          class="style-input-1"
        >
          <mat-label>Nombre de la entidad financiera</mat-label>
          <mat-select formControlName="bancoBeneficiario" required>
            <mat-option *ngFor="let banco of bancos" [value]="banco.id">{{
              banco.entidad
            }}</mat-option>
          </mat-select>
          <span matSuffix class="icon-chevron-down"></span>
        </mat-form-field>
        <div
          fxFlex="100%"
          fxFlex.xs="100%"
          [ngClass]="{ 'max-w-100': !esBeneficiario }"
          class="mb-10"
        >
          <label
            fxLayout="column"
            id="radio-group-label"
            [ngClass]="{ 'no-ben': !esBeneficiario }"
            class="margin-radio label"
            >Tipo de Cuenta
            <span *ngIf="!esBeneficiario"> Bancolombia</span></label
          >
          <mat-radio-group
            fxLayout.xs="column"
            fxLayout.sm="column"
            fxLayout.md="column"
            fxLayoutGap="40px"
            [ngClass]="{
              'al-count-yes': !esBeneficiario,
              'al-count-not': esBeneficiario
            }"
            required
            fxLayoutAlign.xs="flex-start"
            fxLayoutAlign.sm="flex-start"
            fxLayoutAlign.md="flex-start"
            class="margin-radio mt-10"
            formControlName="tipoCuentaBeneficiario"
          >
            <mat-radio-button value="S">Cuenta de Ahorros</mat-radio-button>
            <mat-radio-button value="D">Cuenta Corriente</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field
          class="font-dark-os-16 mt-30"
          fxFlex="50%"
          fxFlex.xs="100%"
          class="style-input-1"
        >
          <mat-label
            >Número de Cuenta<span *ngIf="!esBeneficiario">
              Bancolombia</span
            ></mat-label
          >
          <input
            matInput
            required
            formControlName="numeroCuentaBeneficiario"
            appNumberInputOnly
            type="text"
            minlength="9"
            maxlength="11"
            id="numeroCuenta"
          />
          <mat-error
            *ngIf="registerForm.controls['numeroCuentaBeneficiario'].invalid"
            >{{ getErrorMessage() }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="style-conf-count" fxFlex="49%" fxFlex.xs="100%">
          <mat-label>Confirma el número de Cuenta</mat-label>
          <input
            matInput
            required
            formControlName="numeroCuentaBeneficiarioConf"
            onCopy="return false"
            onDrag="return false"
            onDrop="return false"
            onPaste="return false"
            autocomplete="off"
            appNumberInputOnly
            type="text"
            minlength="9"
            maxlength="11"
            id="numeroCuentaConfirm"
          />
          <mat-error
            *ngIf="
              registerForm.controls['numeroCuentaBeneficiarioConf'].invalid
            "
          >
            {{ getErrorMessageConf() }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="verInfoRep" expanded="true" class="mt-15">
    <mat-expansion-panel-header>
      <div class="font-dark-os-16 center-span">
        <!-- <i class="icon-building"></i> -->
        <img
          class="img-side"
          src="../../../assets/icons/fonts/supervisor_account_FILL0_wght200_GRAD0_opsz48.svg"
          alt="companys"
        />
        <h4>Información del representante legal</h4>
      </div>
    </mat-expansion-panel-header>
    <div fxLayout="row wrap">
      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Nombre(s)</mat-label>
        <input
          matInput
          required
          formControlName="nombreRep"
          appOnlyLetters
          type="text"
          minlength="3"
          maxlength="50"
        />
      </mat-form-field>
      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Apellidos</mat-label>
        <input
          matInput
          required
          formControlName="apellidosRep"
          appOnlyLetters
          type="text"
          minlength="3"
          maxlength="50"
        />
      </mat-form-field>
      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Tipo de documento</mat-label>
        <mat-select
          id="tipoDocumentoRep"
          required
          name="tipoDocumentoRep"
          formControlName="tipoDocumentoRep"
        >
          <mat-option value="CC">Cedula de Ciudadanía</mat-option>
          <mat-option value="CE">Cédula de Extranjeria</mat-option>
          <mat-option value="PA">Pasaporte</mat-option>
          <mat-option value="SC">Salvoconducto de Permanencia</mat-option>
          <mat-option value="CD">Carnet Diplomatico </mat-option>
        </mat-select>
        <span matSuffix class="icon-chevron-down"></span>
      </mat-form-field>
      <mat-form-field
        *ngIf="!esPA"
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Número de documento</mat-label>
        <input
          matInput
          required
          formControlName="numDocumentoRep"
          appNumberInputOnly
          type="text"
          minlength="3"
          maxlength="10"
          id="inputCC"
        />
        <mat-error *ngIf="registerForm.controls['numDocumentoRep'].invalid"
          >{{ getErrorMessageCC() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="!esPA"
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Confirma el número de documento</mat-label>
        <input
          matInput
          required
          formControlName="numDocumentoRepConfirm"
          appNumberInputOnly
          type="text"
          minlength="3"
          maxlength="10"
          onCopy="return false"
          onDrag="return false"
          onDrop="return false"
          onPaste="return false"
          autocomplete="off"
          id="inputCCConfirm"
        />
        <mat-error
          *ngIf="registerForm.controls['numDocumentoRepConfirm'].invalid"
        >
          {{ getErrorMessageCCConfirm() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="esPA"
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Número de documento</mat-label>
        <input
          matInput
          required
          formControlName="numDocumentoRep"
          appAlphaNumeric
          type="text"
          minlength="3"
          maxlength="10"
          id="inputPA"
        />
        <mat-error *ngIf="registerForm.controls['numDocumentoRep'].invalid">
          <span>{{ getErrorMessageAlpha() }}</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="esPA"
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Confirma el número de documento</mat-label>
        <input
          matInput
          required
          formControlName="numDocumentoRepConfirm"
          appAlphaNumeric
          type="text"
          minlength="3"
          maxlength="10"
          onCopy="return false"
          onDrag="return false"
          onDrop="return false"
          onPaste="return false"
          autocomplete="off"
          id="inputPAConfirm"
        />
        <mat-error
          *ngIf="registerForm.controls['numDocumentoRepConfirm'].invalid"
        >
          {{ getErrorMessageAlphaConfirm() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Correo electrónico</mat-label>
        <input matInput required formControlName="correoRep" type="email" />
      </mat-form-field>
      <mat-form-field
        class="font-dark-os-16"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="style-input-1"
      >
        <mat-label>Teléfono celular</mat-label>
        <input
          matInput
          required
          formControlName="celularRep"
          appCelularInputOnly
          type="text"
          minlength="10"
          maxlength="10"
          id="inputCel"
        />
        <mat-error *ngIf="registerForm.controls['celularRep'].invalid">{{
          getErrorMessageCelRep()
        }}</mat-error>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
  <div
    class="container-button button-next"
    fxLayout="row"
    fxFlex="100%"
    fxLayoutAlign="center center"
  >
    <button
      *ngIf="!showExit"
      [disabled]="registerForm.invalid"
      fxFlex.xs="100%"
      (click)="continuarInfoEmpresa()"
      mat-button
      class="btn-primary"
      id="continuar"
    >
      Continuar
    </button>
    <button
      *ngIf="showExit"
      mat-button
      class="btn-primary"
      (click)="exit()"
      id="salirbtn"
    >
      Salir
    </button>
  </div>
  <div>
  </div>
</form>
