import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appOnlyLetters]'
})
export class OnlyLettersDirective {

  regexValid = '^[a-zA-Z \u00f1\u00d1]*$';
  regexNotValid =/[`~!@#$%^&*()_|+\-=÷¿?;:'",.·`<>\{\}\[\]\\\/\s\n]/g;
  @Input() isValid: boolean;

  constructor(private el: ElementRef) { }


  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.isValid = new RegExp(this.regexValid).test(event.key);
    return this.isValid;
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {

      this.el.nativeElement.value = this.el.nativeElement.value.replace(this.regexNotValid, '');
      event.preventDefault();

    }, 100)
  }
}
