<div class="unavailabilityTitle container">
    <div fxLayout="row" fxLayout.sm="column-reverse" fxLayout.xl="column-reverse" fxLayout.xs="column-reverse"
        fxLayoutAlign.xs="center" fxLayoutAlign="center center" fxLayoutAlign.sm="center center"
        fxLayoutAlign.xl="center center">
        <div fxLayout="column" fxLayout.sm="column" fxLayout.xl="column" fxLayout.xs="column" fxFlex="49%"
            class="fixLayout">
            <div>
                <h1>
                    {{title}}
                </h1>
            </div>
            <div>
                <h2>
                    {{descripcion}}
                </h2>
            </div>
            <div fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxLayoutAlign.xl="center">
                <div class="">
                    <button id="btn-offer-calculator-continue" mat-button class="btn-primary"
                         (click)="redirectHome()">
                        IR AL INICIO
                    </button>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxLayout.sm="column" fxLayout.xl="column" fxLayout.xs="column" fxFlex="58%"
            class="fixLayout">
            <div fxLayoutAlign="center">
                <img src="assets/img/banner.svg" alt="Imagen No Disponible" width="90%" />
            </div>
        </div>
    </div>
</div>