import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

export interface CiudadDepartamento {
  CodCiudad: number;
  codDepartamento: string;
  id: string;
  departamentoCiudad: string;
  correo: string[];
}

export interface Ciiu {
  id: string;
  ActividadEconomica: string;
  ciiu: string;
}

@Injectable({
  providedIn: "root",
})
export class CatalogService {
  // private URL_AMBIENTE = "https://qaabrircuenta.grupobancolombia.com";
  public URL_AMBIENTE = window.location.origin;

  public ENDPOINT_CATALOGO =
    "/com.bancolombia.clientes.api.recursos/api/resources/getResource";

  public siteKey: string;
  public iscontingencyActive: boolean;
  public isPapActive: boolean;
  public isPaefActive: boolean;
  public catalogCIIU: Ciiu[];
  public catalogCiudadDepartamento: CiudadDepartamento[];
  public catalogBancos: [];
  constructor(private http: HttpClient) {}

  cargarCatalogos() {
    const body = {
      recurso: "2",
      idAplicacion: "infoSubsidio",
    };
    return this.http.post(
      this.URL_AMBIENTE + this.ENDPOINT_CATALOGO,
      body,
      httpOptions
    );
  }
  cargarCatalogosParametrizables() {
    const body = {
      recurso: "2",
      idAplicacion: "appSubsidio",
    };
    return this.http.post(
      this.URL_AMBIENTE + this.ENDPOINT_CATALOGO,
      body,
      httpOptions
    );
  }

  ordenarCatalogos(data: any) {
    data = data.variables.variables;
    this.catalogCIIU = JSON.parse(data.find((i) => i.id === "ciiu").valor);
    this.catalogCiudadDepartamento = JSON.parse(
      data.find((i) => i.id === "ciudadDepartamento").valor
    );
    this.catalogBancos = JSON.parse(data.find((i) => i.id === "bancos").valor);
  }

  jsonIp() {
    return this.http.get<{ ip: string }>("https://jsonip.com");
  }
}
